import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { Loader, SoundPackage } from 'bpm-sounds-generic';
import { AlbumQueryAttributes } from '../api/endpoints/types';
import { DefaultProps, AlbumHeader, Divider } from "bpm-sounds-generic";
import { SoundAPI } from '../api/endpoints/sound';
import { Analytics } from 'src/manager/analyticsmanager';
import SyncManagedSoundlist from 'src/components/SyncManagedSoundlist';
import { AlbumAPI } from 'src/api/endpoints/album';


const IndividualAlbum: React.FC<RouteComponentProps<{ id: string }> & DefaultProps> = (props) => {

    const [pack, setPack] = React.useState<SoundPackage>()
    const [filter, setFilter] = React.useState<AlbumQueryAttributes>({ album_id: props.match.params.id })

    React.useEffect(() => {
        setPack(undefined)
        AlbumAPI.getAlbumById(props.match.params.id).then(({ pack }) => {
            setPack(pack)
            Analytics.trackView('album', pack.id)
        })
        setFilter((filter) => {
            return { ...filter, sound_package_id: props.match.params.id }
        })
    }, [props.match.params.id])

    if (!pack) {
        return <Loader centered style={{ marginTop: 20 }} />
    }

    return (
        <>
            <AlbumHeader {...props} pack={pack} tags={[]} selectedTags={[]}
                onTagClick={() => { }} onSoundPackageLikeToggled={props.onSoundPackageLikeToggled} />
            <Divider />
            <SyncManagedSoundlist
                key={filter.album_id} {...props}
                apiFunc={SoundAPI.getSounds}
                filter={filter}
                onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }}
                context={'pack'}
                hideFilterKeys={['album_id', 'sound_package_id']} />
        </>
    )
}

export default withRouter(IndividualAlbum)

