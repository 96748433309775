import React, { useEffect } from 'react'
import { MetaAPI } from '../api/endpoints/meta';
import { DefaultProps, Loader, ButtonPanel, BannerRenderer } from 'bpm-sounds-generic';
import { Analytics } from '../manager/analyticsmanager';
import { Genre } from 'src/api/models/genre';

const Genres = (props: DefaultProps) => {
    const [genres, setGenres] = React.useState<Genre[]>()

    useEffect(() => {
        MetaAPI.getGenres().then(resp => {
            setGenres(resp)
        })
    }, [])

    if (!genres) {
        return <Loader centered style={{ marginTop: '10%' }}
            size={'4rem'}
        />
    }

    return <>
        <BannerRenderer location={'instruments'} onItemClick={() => Analytics.trackClick} noDivider />

        {genres.map(genre => {
            return (
                <ButtonPanel
                    key={genre.id}
                    header={genre.name}
                    onHeaderClick={() => {
                        props.onGenreClick(genre)
                    }}
                    items={genre.SubGenres}
                    onClick={(tag) => {
                        props.onSubGenreClick(genre)
                    }}
                />
            )

        })}
    </>
}

export default Genres
