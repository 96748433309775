import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { AlbumQueryAttributes, SoundDisplayAttributes, SoundQueryAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps } from "bpm-sounds-generic";
import { Button, EditModeContextConsumer, ManagedSoundList, Divider } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Collection } from 'src/api/models/collection';
import SyncManagedSoundlist from 'src/components/SyncManagedSoundlist';
import { CollectionAPI } from 'src/api/endpoints/collections';
import { Analytics } from 'src/manager/analyticsmanager';

const DriveDetail: React.FC<RouteComponentProps<{ collectionId: string, name: string }> & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<SoundQueryAttributes>({ collection_id: props.match.params.collectionId })
    const [collection, setCollection] = React.useState<Collection>()

    React.useEffect(() => {
        setCollection(undefined)
        setFilter({ collection_id: props.match.params.collectionId })
        CollectionAPI.getCollection(props.match.params.collectionId).then(setCollection)
    }, [props.match.params.collectionId])

    return (
        <>
            <Divider small />
            <BannerRenderer location={'drive'} onItemClick={() => Analytics.trackClick} />
            <SyncManagedSoundlist
                key={filter.collection_id} {...props}
                pagingMode='manual'
                drive={collection} title={collection?.name || ' '}
                context={'drive:' + props.match.params.collectionId}
                apiFunc={SoundAPI.getSounds}
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} hideFilterKeys={['collection_id']} leftHeaderActionRenderer={() => {
                    return <EditModeContextConsumer>{edit => {
                        if (edit) {
                            return <EditMode driveId={filter.collection_id!} />
                        }
                        return null
                    }}</EditModeContextConsumer>
                }} />
        </>
    )
}
const EditMode = (props: { driveId: string }) => {
    const [show, setShow] = React.useState(false)
    return <>
        <Button buttonText='Create Curated Set'
            style={{ display: 'inline', marginLeft: 16 }}
            onClick={() => {
                setShow(true)
            }}
            variation={'tag'}
            border={true}

        />
        {/* <PopupCreateCuratedset driveId={props.driveId} closed={!show} onClosed={() => {
            setShow(false)
        }} /> */}
    </>
}
export default withRouter(DriveDetail)