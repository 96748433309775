import { SubscriptionInfo } from "bpm-client-api"
import { MembershipInfo } from "../api/endpoints/user"

export function planToDescription(info: MembershipInfo) {
    let infoText = ''
    switch (info.membership_info) {
        case SubscriptionInfo.Standard:
            infoText = 'Standard Membership Plan'
            break;
        case SubscriptionInfo.Premium:
            infoText = 'Premium Membership Plan'
            break;
        case SubscriptionInfo.Pro:
            infoText = 'Premium Plus Membership Plan'
            break;
    }
    if (info.status == 'ongoing') {
        infoText += ` renews on ${new Date(info.next_billing).toLocaleDateString()}`
    }
    return infoText
}
export type PlanType = 'one-time' | 'upgrade' | 'downgrade' | 'subscribe'
export interface Plan {
    length?: string;
    title: string;
    description?: string;
    subtext?: string;

    oldPrice?: string;
    price: string;
    percentOff?: string;

    cta: string;
    filter: {
        subscription: SubscriptionInfo;
        eventType?: string;
        only_inactive?: true
    }

    id: number;
    type: PlanType
    trialPrice?: number
    free?: number;
}


export function getAllPlans(currentPlan: SubscriptionInfo, trialApplicable: boolean): Plan[] {
    const showTrialPlan = currentPlan == SubscriptionInfo.None && trialApplicable
    return [

        // Default
        {
            title: 'Standard',
            description: '+25 Promo Credits',
            subtext: '75 Credits Monthly Access to 5 Drives',
            oldPrice: '$4.99/month',
            price: '$0.99/first month',
            percentOff: '(80% off)',
            cta: currentPlan == SubscriptionInfo.None ? 'Subscribe' : 'Downgrade',
            filter: {
                subscription: showTrialPlan ? SubscriptionInfo.Standard : currentPlan,
            },
            id: 42,
            type: currentPlan == SubscriptionInfo.None ? 'subscribe' : 'downgrade' as PlanType,
            trialPrice: trialApplicable ? 0.99 : undefined,
            free: 25,
        },
        {
            title: 'Standard',
            description: '+25 Promo Credits',
            subtext: '75 Credits Monthly Access to 5 Drives',
            price: '$4.99/month',
            cta: currentPlan == SubscriptionInfo.None ? 'Subscribe' : 'Downgrade',
            filter: {
                subscription: !showTrialPlan ? SubscriptionInfo.Standard : SubscriptionInfo.None,
            },
            id: 42,
            type: currentPlan == SubscriptionInfo.None ? 'subscribe' : 'downgrade' as PlanType,
            trialPrice: trialApplicable ? 0.99 : undefined,
            free: 25,
        },
        {
            title: 'Premium',
            description: '+50 Promo Credits',
            subtext: '200 Credits Monthly Access to 20 Drives',
            price: '$9.99',
            cta: currentPlan == SubscriptionInfo.None ? 'Subscribe' : currentPlan < SubscriptionInfo.Premium ? 'Upgrade' : 'Downgrade',
            filter: {
                subscription: SubscriptionInfo.Premium,
            },
            id: 43,
            type: currentPlan == SubscriptionInfo.None ? 'subscribe' : currentPlan < SubscriptionInfo.Premium ? 'upgrade' : 'downgrade' as PlanType,
            free: 50,
        },
        {
            title: 'Premium Plus',
            description: '+75 Promo Credits',
            subtext: '375 Credits Monthly Access to 30 Drives',
            price: '$14.99',
            cta: currentPlan == SubscriptionInfo.None ? 'Subscribe' : 'Upgrade',
            filter: {
                subscription: SubscriptionInfo.Pro,
            },
            id: 44,
            type: currentPlan == SubscriptionInfo.None ? 'subscribe' : 'upgrade' as PlanType,
            free: 75,
        },

    ]
}

export function getAvailablePlans(
    currentPlan: SubscriptionInfo = SubscriptionInfo.None,
    trialApplicable: boolean
): Plan[] {
    return (
        getAllPlans(currentPlan!, trialApplicable)
            .filter((p) => {
                return (
                    p.filter.subscription != currentPlan
                )
            })
    )
}