import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { SyncSound } from "../models/sound";
import { AlbumQueryAttributes, SoundDisplayAttributes } from "./types";
import { PagingInfo, PagingInfoResponse } from "bpm-sounds-generic";
import { mapSyncSoundToSound, transformSoundResponse } from "src/util/transformer";

export class SoundAPI {

    public static getSounds(query: AlbumQueryAttributes & SoundDisplayAttributes, paging: PagingInfo, token?: string) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds', HTTPMethod.GET, APIVersion.v1_0, { ...query, ...paging });

        return transformSoundResponse<{ data: SyncSound[], pagination: PagingInfoResponse, token?: string }>(apiRequest.send().then((response) => {
            return { ...response as { data: SyncSound[], pagination: PagingInfoResponse }, token }
        }));
    }

    public static getSound(id: string) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sound/' + id, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return mapSyncSoundToSound([response.data])[0]
        });
    }

    public static getTrendingSounds(query: AlbumQueryAttributes, paging: PagingInfo, token?: string) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds/trending', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return transformSoundResponse<{ data: SyncSound[], pagination: PagingInfoResponse }>(apiRequest.send().then((response) => {
            return { ...response as { data: SyncSound[], pagination: PagingInfoResponse }, token }
        }))
    }

    public static getFeaturedSounds(query: AlbumQueryAttributes, paging: PagingInfo, token?: string) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds/featured', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return transformSoundResponse<{ data: SyncSound[], pagination: PagingInfoResponse }>(apiRequest.send().then((response) => {
            return { ...response as { data: SyncSound[], pagination: PagingInfoResponse }, token }
        }))
    }

    public static getLikedSounds(query: AlbumQueryAttributes, paging: PagingInfo, token?: string) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds/liked', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return transformSoundResponse<{ data: SyncSound[], pagination: PagingInfoResponse }>(apiRequest.send().then((response) => {
            return { ...response as { data: SyncSound[], pagination: PagingInfoResponse }, token }
        }))
    }

    public static getDownloadedSounds(query: AlbumQueryAttributes, paging: PagingInfo, token?: string) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds/downloaded', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return transformSoundResponse<{ data: SyncSound[], pagination: PagingInfoResponse }>(apiRequest.send().then((response) => {
            return { ...response as { data: SyncSound[], pagination: PagingInfoResponse }, token }
        }))
    }

    public static getSimilarSounds(sound: { id: string }, limit = 5) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sound/' + sound.id + '/similar', HTTPMethod.GET, APIVersion.v1_0, { limit });

        return transformSoundResponse<{ data: SyncSound[], pagination: PagingInfoResponse }>(apiRequest.send().then((response) => {
            return { ...response as { data: SyncSound[], pagination: PagingInfoResponse } }
        }))
    }

    public static likeSound(sound: { id: string }) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds/' + sound.id + '/favorite', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return mapSyncSoundToSound([response.data])[0]
        });
    }

    public static unLikeSound(sound: { id: string }) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/sounds/' + sound.id + '/favorite', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return mapSyncSoundToSound([response.data])[0]
        });
    }

    public static downloadSound(sound: { id: string }, location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/sounds/' + sound.id + '/download', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloadMultiSound(sound_ids: string[], location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/sounds/downloadMulti', HTTPMethod.GET, APIVersion.v1_0, { sound_ids, location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static streamSoundVersion(sound: { id: string }, location: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest('/sounds/' + sound.id + '/stream', HTTPMethod.GET, APIVersion.v1_0, { location });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}