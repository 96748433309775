import { mapSyncAlbumToPackage, mapSyncSoundToSound } from "../util/transformer";
import { SoundAPI } from "./endpoints/sound";
import { Album } from "./models/album";
import { SyncSound } from "./models/sound";
import { AdminRequest, HTTPMethod, APIVersion, AdminFileRequest } from "./requests/request";


type AllStringAndPartial<T> = {
    [P in keyof T]?: string;
};
export class AdminAPI {

    public static updateAlbum(id: string, update: Partial<Album>) {
        let apiRequest = new AdminRequest('/album/' + id, HTTPMethod.PUT, APIVersion.v1_0, undefined, update);
        return apiRequest.send().then((response) => {
            return mapSyncAlbumToPackage([response.data])[0]
        });
    }

    public static updateSound(id: string, update: AllStringAndPartial<SyncSound>) {
        let apiRequest = new AdminRequest('/sound/' + id, HTTPMethod.PUT, APIVersion.v1_0, undefined, update);
        return apiRequest.send().then((response) => {
            return SoundAPI.getSound(id)
        });
    }

    public static addSoundTags(id: string, tagIds: string[]) {
        let apiRequest = new AdminRequest<SyncSound>('/sound/' + id + '/tags', HTTPMethod.POST, APIVersion.v1_0, undefined, { id: tagIds });
        return apiRequest.send().then((response) => {
            return mapSyncSoundToSound([response.data])[0]
        });
    }

    public static deleteSoundTag(id: string, tagId: string) {
        let apiRequest = new AdminRequest<SyncSound>('/sound/' + id + '/tags/' + tagId, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return mapSyncSoundToSound([response.data])[0]
        });
    }

    public static getKeys(): Promise<string[]> {
        let apiRequest = new AdminRequest('/keys/names', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getCuratedCategories(): Promise<{ id: string, name: string }[]> {
        let apiRequest = new AdminRequest('/curatedcategory', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data.data
        });
    }

    public static uploadCuratedArtwork(file: File): Promise<{ url: string }> {
        let apiRequest = new AdminFileRequest('/curated/image/temp', HTTPMethod.POST, APIVersion.v1_0, undefined, { image: file });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static uploadCuratedDemo(file: File): Promise<{ url: string }> {
        let apiRequest = new AdminFileRequest('/curated/mp3/temp', HTTPMethod.POST, APIVersion.v1_0, undefined, { image: file });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static createCuratedSet(set: CreateCurated): Promise<CreateCurated & { id: string }> {
        let apiRequest = new AdminRequest('/curated', HTTPMethod.POST, APIVersion.v1_0, undefined, set);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}

export interface CreateCurated {
    artwork_url?: string
    demo_file_path?: string
    name?: string;
    slug?: string
    description?: string
    video_url?: string
    category_id?: string
    drive_id: string
}