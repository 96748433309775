import React, { useContext } from 'react'
import { BPM_SOUNDS_LOGO, BPM_SOUNDS_LOGO_BLACK, FACEBOOK_SVG, TWITTER_SVG, INSTAGRAM_SVG, YOUTUBE_SVG } from "bpm-sounds-generic/assets"
import { UserContext, UserContextConsumer } from "bpm-sounds-generic";

const AccountFooter = () => {
    const user = useContext(UserContext)

    return (
        <footer className="AccountFooter">
            <div className="left">
                <img className="logo" src={user.theme === 'black' ? BPM_SOUNDS_LOGO : BPM_SOUNDS_LOGO_BLACK} alt="bpm create logo" />
                <span>@ 2021 BPM Create</span>
            </div>
            <div className="middle">
                <a className="f-social__item" href="https://www.facebook.com/bpmcreate" aria-label="Our Facebook page" target="_blank">
                    <img src={FACEBOOK_SVG} alt="facebook" />

                </a>

                <a className="f-social__item" href="https://twitter.com/bpmcreate" aria-label="Our Twitter page" target="_blank">
                    <img src={TWITTER_SVG} alt="twitter" />

                </a>

                <a className="f-social__item" href="https://instagram.com/bpmcreate" aria-label="Our Instagram page" target="_blank">
                    <img src={INSTAGRAM_SVG} alt="instagram" />
                </a>

                <a className="f-social__item" href="https://www.youtube.com/channel/UCXkN2-PokHovKuoudndpyWw" aria-label="Our Youtube channel" target="_blank">
                    <img src={YOUTUBE_SVG} alt="youtube" />

                </a>

            </div>
            <div className="right">
                <div className="copy"><strong>&copy; 2020 BPM Create</strong> &nbsp;|&nbsp; All Rights Reserved</div>
                <div className="terms-conditions">
                    <UserContextConsumer>{user => {
                        return <a className="terms" href={"https://www.bpmsupreme.com/bpm-create/terms?" + (user.theme == 'white' ? 'light' : '')} target="_blank">Terms & Conditions</a>
                    }}</UserContextConsumer>
                    <div className="divider">|</div>
                    <a className="privacy" href="https://www.bpmsupreme.com/privacy-policy" target="_blank">Privacy Policy</a>
                </div>

            </div>
        </footer>
    )
}

export default AccountFooter;