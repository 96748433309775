import React from 'react';
import { UsersAPI, MembershipInfo } from '../../api/endpoints/user';
import { SubscriptionInfo, UsersAPI as BPMUsersAPI, PaymentAPI, errorToString } from 'bpm-client-api';
import { PaymentHistoryItem, PaymentInstrument } from 'bpm-client-api/lib/endpoints/user';
import { withRouter, RouteComponentProps } from 'react-router';
import { PAYPAL, CIRCULAR_CHECKMARK_SVG } from "bpm-sounds-generic/assets";
import * as toastr from 'toastr'
import {
    CardSale,
    CreditCardInput,
    Popup,
    Loader,
    OrderHistory,
    CreditCard,
    Button,
    FAQ
} from 'bpm-sounds-generic';
import { getAllPlans, getAvailablePlans, planToDescription } from 'src/util/subscription';
import AccountFooter from '../../components/AccountFooter';
import { FAQItems } from './faq';
class Billing extends React.Component<RouteComponentProps, {
    loading: boolean,
    canceling: boolean,
    memberShipInfo?: MembershipInfo
    trialApplicable: boolean

    payments?: PaymentHistoryItem[],
    paymentInstrument?: PaymentInstrument

    selectedPaymentOption: 'card' | 'paypal'
    changingPaymentInfo: boolean
    changingPaymentInfoShow: boolean
}> {
    private creditCardForm: CreditCardInput | null = null;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            loading: true,
            canceling: false,
            selectedPaymentOption: 'card',
            changingPaymentInfo: false,
            changingPaymentInfoShow: false,
            trialApplicable: false
        }
    }

    componentDidMount() {
        UsersAPI.getMembership().then((info) => {
            this.setState({ memberShipInfo: info.activeSubscription, trialApplicable: info.trialApplicable, loading: false })
        }).catch(() => {
            this.setState({ trialApplicable: true, loading: false })
        })
        BPMUsersAPI.getPayments('sync').then((payments) => {
            this.setState({ payments })
        }).catch((err) => {
            this.setState({ payments: [] })
        })
        BPMUsersAPI.getPaymentInstrument(false, 'sync').then(instrument => {
            this.setState({ paymentInstrument: instrument })
        })
    }

    private cancelPlan() {
        Popup.cancelSubscription().then(action => {
            if (action == 'ok') {
                this.setState({ canceling: true })
                PaymentAPI.cancelSubscription('sync').then(() => {
                    window.location.reload()
                }).catch(err => {
                    this.setState({ canceling: false })
                    errorToString(err).forEach(error => {
                        toastr.error(error)
                    })
                })
            }
        })
    }

    private changePaymentInfo() {
        if (this.state.selectedPaymentOption == 'card') {
            if (!this.creditCardForm || !this.creditCardForm.valid()) {
                toastr.error('Please insert a valid credit card')
            } else {
                this.setState({ changingPaymentInfo: true })
                const card = this.creditCardForm.getCard()
                PaymentAPI.changeSubscription(this.state.memberShipInfo!.order.package_id, new Date(), {
                    expMonth: card.month,
                    expYear: card.year,
                    cvv: card.cvv,
                    pan: card.number,
                }).then((info) => {
                    window.location.reload()
                }).catch(err => {
                    this.setState({ changingPaymentInfo: false })
                    errorToString(err).forEach(error => {
                        toastr.error(error)
                    })
                })
            }
        } else if (this.state.selectedPaymentOption == 'paypal') {
            const redirect = window.location.protocol + '//' + window.location.hostname + '/billing'
            this.setState({ changingPaymentInfo: true })
            PaymentAPI.changeSubscription(this.state.memberShipInfo!.order.package_id, new Date(),
                { redirect_base: redirect }).then((info) => {
                    window.location.assign(info.redirect)
                }).catch(err => {
                    this.setState({ changingPaymentInfo: false })
                    errorToString(err).forEach(error => {
                        toastr.error(error)
                    })
                })
        }
    }

    renderChangePaymentInfo() {
        if (!this.state.changingPaymentInfoShow || !this.state.memberShipInfo || this.state.memberShipInfo.membership_info == SubscriptionInfo.None) {
            return null
        }
        return <div className='billing-change-payment-method'>
            <div className="payment-method__container">
                <div className='content-head'>
                    Change Payment Method:
                </div>
                <div className="payment-method__item">
                    <input id="payment-form-method-card" name="method" type="radio" value="card" checked={this.state.selectedPaymentOption == 'card'} onChange={(e) => {
                        this.setState({ selectedPaymentOption: e.currentTarget.checked ? 'card' : 'paypal' })
                    }} />
                    <label htmlFor="payment-form-method-card">Credit Card</label>
                </div>
                <div className="payment-method__item">
                    <input id="payment-form-method-paypal" name="method" type="radio" value="paypal" checked={this.state.selectedPaymentOption == 'paypal'} onChange={(e) => {
                        this.setState({ selectedPaymentOption: !e.currentTarget.checked ? 'card' : 'paypal' })
                    }} />
                    <label htmlFor="payment-form-method-paypal">Paypal</label>
                </div>
                {this.state.selectedPaymentOption == 'card' && this.renderCreditCard()}
            </div>
            <div>
                <div className='content-head' style={{ marginTop: 14 }}>
                    <Button loading={this.state.changingPaymentInfo} backgroundColor={'var(--primary)'} textColor={'white'} buttonText={'Change'} onClick={() => {
                        this.changePaymentInfo()
                    }} />
                </div>
            </div>
        </div>
    }

    renderCreditCard() {
        return <CreditCardInput ref={(ref) => {
            this.creditCardForm = ref
        }} />
    }

    renderCurrentPlan() {
        if (this.state.memberShipInfo) {
            const planName = getAllPlans(SubscriptionInfo.None, this.state.trialApplicable).find(p => p.id == this.state.memberShipInfo?.order.package_id)?.title || this.state.memberShipInfo.membership_type
            const description = planToDescription(this.state.memberShipInfo)
            return <>
                <img className="checkmark" src={CIRCULAR_CHECKMARK_SVG} />
                <div className="top-text">{planName}</div>
                <div className="middle-text">{description}</div>
                <div className="small-space" />
                {this.state.memberShipInfo.order.next_cycle_package_change != undefined && <div className="middle-text">Pending change to {getAllPlans(SubscriptionInfo.None, this.state.trialApplicable).find(p => p.id == this.state.memberShipInfo?.order.next_cycle_package_change)?.title} on next billing date</div>}
                <div style={{ display: 'flex', alignContent: 'center', }}>
                    {this.state.memberShipInfo.membership_info < SubscriptionInfo.Pro &&
                        <Button buttonText={'Upgrade Plan'} textColor={'white'} onClick={() => {
                            this.props.history.push('/pricing')
                        }} />}
                    {this.state.memberShipInfo.membership_info > SubscriptionInfo.None && <div style={{ marginLeft: 20 }}>
                        <Button buttonText={'Cancel Membership'} loading={this.state.canceling} textColor={'white'} onClick={() => {
                            this.cancelPlan()
                        }} />
                    </div>}
                </div>
            </>
        } else {
            return <>
                <div className="top-text">No Plan</div>
                {/* <div className="credits-remaining no-middle-text">{info.regular} Credits Remaining</div>
                    <div className="credits-remaining no-middle-text">{info.promo} Promo Credits Remaining</div> */}
                <div className="small-space" />
                <Button buttonText={'Subscribe'} textColor={'white'} hoverEffect={true} onClick={() => {
                    this.props.history.push('/pricing')
                }} />
            </>
        }
    }

    renderPaymentMethod() {
        if (!this.state.paymentInstrument) {
            return null
        }
        if (this.state.paymentInstrument.method == 'authorize') {
            return <>
                <div className="large-space" />
                <div className="credit-card">
                    <div className="title">Payment Method</div>
                    <div className="small-space" />
                    <CreditCard type={this.state.paymentInstrument.card?.cardType} number={this.state.paymentInstrument.card?.cardNumber} />
                </div>
                <div className="change-card" onClick={(e) => {
                    this.setState({ changingPaymentInfoShow: !this.state.changingPaymentInfoShow })
                }}>{this.state.changingPaymentInfoShow ? 'Cancel' : 'Change Payment Method'}</div>
                {this.renderChangePaymentInfo()}
            </>
        }
        if (this.state.paymentInstrument.method == 'paypal') {
            return <>
                <div className="large-space" />
                <div className="credit-card">
                    <div className="title">Payment Method</div>
                    <div className="small-space" />
                    <img src={PAYPAL} width={120} alt="PayPal Logo" />
                    <div className="change-card" onClick={(e) => {
                        this.setState({ changingPaymentInfoShow: !this.state.changingPaymentInfoShow })
                    }}>{this.state.changingPaymentInfoShow ? 'Cancel' : 'Change Payment Method'}</div>
                </div>
                {this.renderChangePaymentInfo()}
            </>
        }

        return null
    }

    render() {
        if (this.state.loading) {
            return <Loader centered />
        }
        const bf_plans = getAvailablePlans(
            this.state.memberShipInfo?.membership_info,
            this.state.trialApplicable).filter((p) => p.filter.eventType == 'black-friday')
        return (<div className="Billing">
            <main>
                <div className="current">
                    <div className="large-space"></div>
                    <div className="header">Billing</div>
                    <div className="small-space" />
                    <div className="title">Your Current Plan</div>
                    <div className="small-space" />
                    <div className="standard-container" style={{ paddingBottom: 20 }}>
                        {this.renderCurrentPlan()}
                    </div>
                </div>
                <div className="large-space" />
                {
                    /* PLAN */
                }
                <div className="available">
                    <div className="title">Available Plan</div>
                    <div className="small-space" />
                    {bf_plans.length > 0 && <>
                        <div className="sale-text sale">Black Friday & Cyber Monday Sale</div>
                        <div className="cards-container">
                            {bf_plans.map((plan, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CardSale {...plan} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </>}
                    <div className="regular-rate-text">Regular Membership Rates</div>
                    <div className="cards-container">
                        {getAvailablePlans(
                            this.state.memberShipInfo?.membership_info,
                            this.state.trialApplicable).filter(p => !p.filter.eventType).map((plan, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CardSale {...plan} cardColors={'standard'} />
                                    </React.Fragment>
                                )
                            })}
                    </div>
                </div>
                {this.renderPaymentMethod()}
                <div className="large-space" />
                <div className="orders">
                    <div className="title">Order History</div>
                    <div className="small-space" />
                    {!this.state.payments && <Loader />}
                    {this.state.payments && <OrderHistory invoices={this.state.payments} />}
                </div>
                <div className="large-space" />

            </main>
            <FAQ FAQItems={FAQItems} />
            <AccountFooter />
        </div>);
    }

}

export default withRouter(Billing)