import React, { useState } from 'react'
import { Divider, Title } from 'bpm-sounds-generic';
import CardGrid from '../components/CardGrid';
import { MetaAPI } from 'src/api/endpoints/meta';
import { errorToString } from 'src/api';
import { Mood } from 'src/api/models/mood';
import { useHistory } from 'react-router'

const Moods = () => {
    const [moods, setMoods] = useState<Mood[]>()
    const history = useHistory()

    React.useEffect(() => {
        MetaAPI.getMoods().then(resp => {
            setMoods(resp)
        }).catch(err => errorToString(err))
    }, [])

    const widx = '264px'
    return (
        <div className="Moods">
            <Divider small />
            <Title
                titleText={'Moods'}
            />
            <Divider small />

            {moods &&
                <CardGrid
                    colMaxWidth={'32rem'}
                    cardGridItems={moods?.map(mood => {
                        return {
                            height: widx,
                            onClick: () => { history.push(`moods/${mood.slug}`) },
                            info: {
                                image: mood.image_url,
                                text: mood.name
                            }
                        }
                    })}
                />
            }
            <Divider />
        </div>
    )
}

export default Moods