import { PagingInfo, PagingInfoResponse, SoundPackage } from "bpm-sounds-generic";
import { mapSyncAlbumToPackage, transformAlbumPackResponse, transformAlbumResponse } from "src/util/transformer";
import { Album } from "../models/album";
import { Category } from "../models/category";
import { Genre } from "../models/genre";
import { Mood } from "../models/mood";
import { SyncSound } from "../models/sound";
import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { AlbumQueryAttributes } from "./types";

export class AlbumAPI {

    public static getAlbums(query: AlbumQueryAttributes, paging: PagingInfo, token?: string) {
        let apiRequest = new AuthenticatedRequest<Album>('/albums', HTTPMethod.GET, APIVersion.v1_0, { ...query, ...paging });
        return transformAlbumResponse(apiRequest.send().then((response) => {
            return { ...response as { data: Album[], pagination: PagingInfoResponse }, token }
        }))
    }

    public static getFeaturedAlbums(paging: PagingInfo) {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/featured', HTTPMethod.GET, APIVersion.v1_0, paging);

        return transformAlbumResponse(apiRequest.send().then((response) => {
            return response as { data: Album[], pagination: PagingInfoResponse }
        }))
    }

    public static getAlbumsByGenre(genreSlug: string) {
        return (paging: PagingInfo): Promise<{ data: { packs: SoundPackage[], genre: Genre }, pagination: PagingInfoResponse }> => {
            let apiRequest = new AuthenticatedRequest<Album>('/albums/byGenre/' + encodeURIComponent(genreSlug), HTTPMethod.GET, APIVersion.v1_0, paging);

            return apiRequest.send().then((response) => {
                return { ...response.data, data: { packs: mapSyncAlbumToPackage([response.data.packs][0]), genre: response.data.genre } } as { data: { packs: SoundPackage[], genre: Genre }, pagination: PagingInfoResponse }
            });
        }
    }

    public static getAlbumsByCategory(categoryName: string) {
        return (paging: PagingInfo): Promise<{ data: { packs: SoundPackage[], category: Category }, pagination: PagingInfoResponse }> => {
            let apiRequest = new AuthenticatedRequest<Album>('/albums/byCategory/' + encodeURIComponent(categoryName), HTTPMethod.GET, APIVersion.v1_0, paging);

            return apiRequest.send().then((response) => {
                return { ...response.data, data: { packs: mapSyncAlbumToPackage([response.data.packs][0]), category: response.data.category } } as { data: { packs: SoundPackage[], category: Category }, pagination: PagingInfoResponse }
            });
        }
    }

    public static getAlbumsByMood(moodName: string) {
        return (paging: PagingInfo): Promise<{ data: { packs: SoundPackage[], mood: Mood }, pagination: PagingInfoResponse }> => {
            let apiRequest = new AuthenticatedRequest<Album>('/albums/byMood/' + encodeURIComponent(moodName), HTTPMethod.GET, APIVersion.v1_0, paging);

            return apiRequest.send().then((response) => {
                return { ...response.data, data: { packs: mapSyncAlbumToPackage([response.data.packs][0]), mood: response.data.mood } } as { data: { packs: SoundPackage[], mood: Mood }, pagination: PagingInfoResponse }
            });
        }
    }


    public static getNewestAlbums(paging: PagingInfo) {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/newest', HTTPMethod.GET, APIVersion.v1_0, paging);

        return transformAlbumResponse(apiRequest.send().then((response) => {
            return response as { data: Album[], pagination: PagingInfoResponse }
        }))
    }

    public static getDownloadedAlbums(paging: PagingInfo) {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/downloaded', HTTPMethod.GET, APIVersion.v1_0, paging);

        return transformAlbumResponse(apiRequest.send().then((response) => {
            return response as { data: Album[], pagination: PagingInfoResponse }
        }))
    }

    public static getSuggestedAlbums(paging: PagingInfo) {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/suggested', HTTPMethod.GET, APIVersion.v1_0, paging);

        return transformAlbumResponse(apiRequest.send().then((response) => {
            return response as { data: Album[], pagination: PagingInfoResponse }
        }))
    }

    public static getTrendingAlbums(paging: PagingInfo) {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/trending', HTTPMethod.GET, APIVersion.v1_0, paging);

        return transformAlbumResponse(apiRequest.send().then((response) => {
            return response as { data: Album[], pagination: PagingInfoResponse }
        }))
    }

    public static getAlbumById(packageId: string): Promise<{ pack: SoundPackage }> {
        let apiRequest = new AuthenticatedRequest('/album/' + packageId, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return { ...response.data, pack: mapSyncAlbumToPackage([response.data.pack])[0] };
        });
    }

    public static getSimilarAlbums(sound: { id: string }, limit = 5) {
        let apiRequest = new AuthenticatedRequest<SyncSound>('/albums/' + sound.id + '/similar', HTTPMethod.GET, APIVersion.v1_0, { limit });

        return transformAlbumPackResponse(apiRequest.send().then((response) => {
            return { ...response as { data: { packs: Album[], current: Album }, pagination: PagingInfoResponse } }
        }))
    }

    public static downloadAlbum(albumId: string, location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/album/' + albumId + '/download', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}