export enum APIErrorType {
    InvalidParameters = "InvalidParametersError",
    AlreadyExists = "ResourceAlreadyExists",
    InvalidGoogleToken = "InvalidGoogleToken",
    InvalidFacebookToken = "InvalidFacebookToken",
    InvalidLogin = "InvalidLoginError",
    ResourceNotFound = "ResourceNotFound",
    NotAccessibleError = "NotAccessibleError",
    UnknownError = "UnknownError",
    NetworkError = "NetworkError",
    MissingAuthorizationError = "MissingAuthorizationError",
    SessionNotFoundError = "SessionNotFoundError",
    DownloadLimitReachedError = "DownloadLimitReachedError",
    NotEnoughCreditsError = "NotEnoughCreditsError",
    InvalidSubscriptionError = "InvalidSubscriptionError"
}

export interface NotEnoughCreditsError {
    type: APIErrorType.NotEnoughCreditsError;
    status: 403;
    credits_available: number;
    credits_required: number;
    trial_applicable: boolean
    message: string;
}
