import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { Collection } from "../models/collection";

export class CollectionAPI {

    public static getCollections(): Promise<Collection[]> {
        let apiRequest = new AuthenticatedRequest<Collection>('/collections', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getCollection(id: string): Promise<Collection> {
        let apiRequest = new AuthenticatedRequest<Collection>('/collection/' + id, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static deleteCollection(id: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest<void>('/collection/' + id, HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static createCollection(name: string): Promise<Collection> {
        let apiRequest = new AuthenticatedRequest<Collection>('/collections', HTTPMethod.POST, APIVersion.v1_0, undefined, { name });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static addToCollection(collection_id: string, sound_id: string): Promise<Collection> {
        let apiRequest = new AuthenticatedRequest<Collection>(`/collection/${collection_id}/${sound_id}`, HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static removeFromCollection(collection_id: string, sound_id: string): Promise<Collection> {
        let apiRequest = new AuthenticatedRequest<Collection>(`/collection/${collection_id}/${sound_id}`, HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}