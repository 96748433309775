import { PagingInfo, CuratedCategory, CuratedPackage, PagingInfoResponse } from "bpm-sounds-generic";
import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";

export class CuratedAPI {

    public static getCuratedByCategory(paging: PagingInfo): Promise<{ data: ({ category: CuratedCategory, sets: CuratedPackage[] })[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest('/curated/byCategory', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: { category: CuratedCategory, sets: CuratedPackage[] }[], pagination: PagingInfoResponse }
        });
    }

    public static getFreeCurated(paging: PagingInfo): Promise<{ data: CuratedPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<CuratedPackage>('/curated/free', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: CuratedPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getCuratedById(id: string): Promise<{ pack: CuratedPackage }> {
        let apiRequest = new AuthenticatedRequest('/curated/' + id, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static likeCurated(sound: CuratedPackage): Promise<CuratedPackage> {
        let apiRequest = new AuthenticatedRequest<CuratedPackage>('/curated/' + sound.id + '/favorite', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static unLikeCurated(sound: CuratedPackage): Promise<CuratedPackage> {
        let apiRequest = new AuthenticatedRequest<CuratedPackage>('/curated/' + sound.id + '/favorite', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloadCurated(soundpack: CuratedPackage, location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/curated/' + soundpack.id + '/download', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

}