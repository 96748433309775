import { BPMSlider, Button } from 'bpm-sounds-generic'
import React from 'react'

const Testing = () => {
    return <>
        <BPMSlider
            caption={'Length'}
            captionOrientation={'left'}
            onlySlider={true}
        />

        <Button
            buttonText="Genres"
            backgroundColor="#2E2E2E"
            textColor="white"
            fontSize="1.7rem"
        />
        <Button
            buttonText="Moods"
            backgroundColor="#2E2E2E"
            textColor="white"
            fontSize="1.7rem"
        />


    </>
}

export default Testing