import { Genre, Label, Banner } from "bpm-sounds-generic";
import { Category } from "../models/category";
import { Mood } from "../models/mood";
import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";

export class MetaAPI {

    public static getGenres(): Promise<Genre[]> {
        let apiRequest = new AuthenticatedRequest<Genre>('/genres', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Genre[]>response.data
        });
    }

    public static getMoods(): Promise<Mood[]> {
        let apiRequest = new AuthenticatedRequest<Mood>('/moods', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Mood[]>response.data
        });
    }

    public static getCategories(): Promise<Category[]> {
        let apiRequest = new AuthenticatedRequest<Category>('/categories', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Category[]>response.data
        });
    }


    public static getLabels(): Promise<Label[]> {
        let apiRequest = new AuthenticatedRequest<Label>('/labels', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Label[]>response.data
        });
    }

    public static getBanner(location: string): Promise<Banner | null> {
        let apiRequest = new AuthenticatedRequest<Banner>('/banner', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return <Banner>response.data
        });
    }

}