import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps } from "bpm-sounds-generic";
import { AlbumQueryAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { Divider } from 'bpm-sounds-generic';
import SyncManagedSoundlist from '../components/SyncManagedSoundlist';


const MyDownloads: React.FC<RouteComponentProps & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<AlbumQueryAttributes>({})

    return (
        <div className="MyDownloads">
            {/* <Divider small /> */}
            {/* <ManagedAlbumRow {...props} context={'downloaded_packs'} title='My Packs' apiFunc={SoundAPI.getDownloadedSounds} /> */}
            <Divider />
            <SyncManagedSoundlist pagingMode='manual' {...props} title='My Songs' filter={filter} onFilterChange={(newFilter) => {
                setFilter((filter) => {
                    return { ...filter, ...newFilter }
                })
            }} apiFunc={SoundAPI.getDownloadedSounds} context={'mylibrary'} />
        </div>
    )
}


export default withRouter(MyDownloads)