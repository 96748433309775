import React from 'react'

export interface CardGridItem {
    maxWidth?: string | number;
    height?: string | number;
    info: {
        image: string;
        text?: string;
    }
    onClick?: () => void;
}

const CardGridItem = (props: CardGridItem) => {
    const { maxWidth, height, info } = props

    return <>
        <div className="CardGridItem" onClick={props.onClick}>
            <div className="img-wrapper">
                <img
                    src={info.image}
                    alt=""
                    style={{
                        maxWidth: maxWidth,
                        height: height,
                    }}
                />
            </div>
            <div className="title-text" onClick={props.onClick}>
                {info.text}
            </div>
        </div>
    </>
}

export default CardGridItem