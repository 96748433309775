import React, { useContext, useState } from 'react'
import { SOUNDS_LOGO_SVG, BPM_SOUNDS_LOGO, BPM_SOUNDS_LOGO_BLACK } from "bpm-sounds-generic/assets"
import { CHEVRON_DOWN_SVG } from 'bpm-sounds-generic/assets'

import { UserContext } from "bpm-sounds-generic";
import { useHistory } from 'react-router';
import { AccountMenu } from 'bpm-sounds-generic';
import { ClickAwayListener } from '@material-ui/core';
import { SYNC_LOGO, SYNC_LOGO_DARK } from 'src/assets';


const AccountHeader = ({ onLogout, defaultSelected }: { onLogout: () => void, defaultSelected: 'account' | 'billing' }) => {
    const user = useContext(UserContext)
    const history = useHistory()
    const [expanded, setExpanded] = useState(false)
    const [selected, setSelected] = useState(defaultSelected)
    return (
        <header className="AccountHeader">
            <img className="logo" src={user.theme === 'black' ? SYNC_LOGO : SYNC_LOGO_DARK} alt="Create Logo" onClick={() => history.push('/')} />
            <img
                className="logo-mobile hide"
                src={SOUNDS_LOGO_SVG}
                onClick={() => { history.push('/') }}
                alt="BPM LOGO"
            />
            <div className="nav-btns">

                <span className={`account ${window.location.pathname === '/account' ? 'selected' : ''}`} onClick={() => { history.push('/account'); setSelected('account') }} >Account</span>
                <span className={`billing ${window.location.pathname === '/billing' ? 'selected' : ''}`} onClick={() => { history.push('/billing'); setSelected('billing') }} > Billing</span>
                <span className="browse" onClick={() => { history.push('/browse') }} > Browse</span>
            </div>
            <ClickAwayListener onClickAway={() => {
                setExpanded(false)
            }}>
                <div
                    className="account-container flex"
                    style={{ alignItems: "center" }}
                    onClick={() => setExpanded(!expanded)}
                >
                    <img
                        className="avatar"
                        src={user.profile_image_thumbnail_url || SOUNDS_LOGO_SVG}
                        onClick={() => {
                        }}
                        alt="BPM Avatar"
                    />
                    <div
                        className="avatar-dropdown-container no-select"
                        onClick={() => {
                        }}
                    >
                        <img
                            className="chevron"
                            src={CHEVRON_DOWN_SVG}
                            alt="Dropdown Menu Chevron"
                        ></img>
                    </div>

                    <AccountMenu expanded={expanded} onLogout={onLogout}
                        isFullScreen={(document as any).webkitIsFullScreen || (document as any).msFullscreenElement || document.fullscreen}
                        onEnterFullScreen={(document.fullscreenEnabled || (document as any).webkitFullscreenEnabled ||
                            (document as any).mozFullScreenEnabled ||
                            (document as any).msFullscreenEnabled) ? () => {
                                var docElm = document.documentElement;
                                if ((document as any).webkitIsFullScreen || (document as any).msFullscreenElement || document.fullscreen) {
                                    try {
                                        if (document.exitFullscreen) {
                                            document.exitFullscreen();
                                        }
                                        else if ((document as any).mozCancelFullScreen) {
                                            (document as any).mozCancelFullScreen();
                                        }
                                        else if ((document as any).webkitCancelFullScreen) {
                                            (document as any).webkitCancelFullScreen();
                                        }
                                        else if ((document as any).msExitFullscreen) {
                                            (document as any).msExitFullscreen();
                                        }
                                    } catch (error) {

                                    }
                                } else {
                                    try {
                                        if (docElm.requestFullscreen) {
                                            docElm.requestFullscreen();
                                        }
                                        else if ((docElm as any).mozRequestFullScreen) {
                                            (docElm as any).mozRequestFullScreen();
                                        }
                                        else if ((docElm as any).webkitRequestFullScreen) {
                                            (docElm as any).webkitRequestFullScreen();
                                        }
                                        else if ((docElm as any).msRequestFullscreen) {
                                            (docElm as any).msRequestFullscreen();
                                        }
                                    } catch (error) {

                                    }
                                }
                            } : undefined}
                    />
                </div>
            </ClickAwayListener>
        </header>
    )
}

export default AccountHeader;