

export default class MapUtils {

    static isPrimitive(obj: any) {
        switch (typeof obj) {
            case "string":
            case "number":
            case "boolean":
                return true;
        }
        return (obj instanceof String || obj === String ||
            obj instanceof Number || obj === Number ||
            obj instanceof Boolean || obj === Boolean ||
            obj instanceof Date || obj === Date);
    }

    static isArray(object: any) {
        if (object === Array) {
            return true;
        } else if (typeof Array.isArray === "function") {
            return Array.isArray(object);
        }
        else {
            return (object instanceof Array);
        }
    }

    static deserialize<T>(jsonObject: any, keyPath?: string): T | undefined {
        return keyPath ? jsonObject[keyPath] : jsonObject;
    }
}
