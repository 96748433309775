import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps } from "bpm-sounds-generic";
import { AlbumQueryAttributes } from '../api/endpoints/types';
import { Divider } from 'bpm-sounds-generic';
import SyncManagedSoundlist from 'src/components/SyncManagedSoundlist';

const Favorites: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<AlbumQueryAttributes>({})

    return (
        <>
            {/* <Divider small /> */}
            {/* <BannerRenderer location={'favorites'} onItemClick={() => Analytics.trackClick} /> */}
            {/* <ManagedAlbumRow {...props} context={'favorite_packs'} apiFunc={SoundAPI.getLikedSounds} title='Favorite Packs' /> */}
            <Divider />
            <SyncManagedSoundlist {...props} title='Favorite Sounds' filter={filter} pagingMode='manual' onFilterChange={(newFilter) => {
                setFilter((filter) => {
                    return { ...filter, ...newFilter }
                })
            }} apiFunc={SoundAPI.getLikedSounds} context={'favorites'} />
        </>
    )
}

export default withRouter(Favorites)