const local = {
    API: 'http://local.bpmsupreme.com:3003',
    ADMIN_API: 'http://local.bpmsupreme.com:3003',
    GENERIC_API: 'http://local.bpmsupreme.com:3002',
    STORAGE_V: '2',
    POOL_URL: 'http://local.bpmsupreme.com:3001',
    BPM_URL: 'https://develop.bpmsupreme.com',
    BPM_APP_URL: 'https://app.develop.bpmsupreme.com',
    LATINO_APP_URL: 'https://dev.latino.bpmsupreme.com',
    SYNC_APP_URL: 'https://local.sync.bpmsupreme.com'
};
const dev = {
    API: 'https://api.develop.sync.bpmsupreme.com',
    ADMIN_API: 'https://api.develop.sync.bpmsupreme.com',
    GENERIC_API: 'https://api.develop.bpmsupreme.com',
    STORAGE_V: '2',
    POOL_URL: 'https://dev.sync.bpmsupreme.com',
    BPM_URL: 'https://develop.bpmsupreme.com',
    BPM_APP_URL: 'https://app.develop.bpmsupreme.com',
    LATINO_APP_URL: 'https://dev.latino.bpmsupreme.com',
    SYNC_APP_URL: 'https://dev.sync.bpmsupreme.com'
};
const prod = {
    API: 'https://api.sync.bpmsupreme.com',
    ADMIN_API: 'https://admin-api.sync.bpmsupreme.com',
    GENERIC_API: 'https://api.bpmsupreme.com',
    STORAGE_V: '2',
    POOL_URL: 'https://sync.bpmsupreme.com',
    BPM_URL: 'https://bpmsupreme.com',
    BPM_APP_URL: 'https://app.bpmsupreme.com',
    LATINO_APP_URL: 'https://latino.bpmsupreme.com',
    SYNC_APP_URL: 'https://sync.bpmsupreme.com'
};

const config = process.env.REACT_APP_STAGE === 'local' ? dev :
    process.env.REACT_APP_STAGE === 'production'
        ? prod
        : dev;
export let Environment = config