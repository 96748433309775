import { PlayableSound, Sound, SoundPackage } from "bpm-sounds-generic";
import { SyncSoundVersion } from "src/api/models/sound_version";
import { Album } from "../api/models/album";
import { SyncSound } from "../api/models/sound";

export function transformSoundResponse<T extends { data: SyncSound[] }>(apiPromise: Promise<T>): Promise<Omit<T, 'data'> & { data: Sound[] }> {
    return apiPromise.then((res) => {
        return {
            ...res,
            data: mapSyncSoundToSound(res.data)
        }
    })
}

export function transformAlbumResponse<T extends { data: Album[] }>(apiPromise: Promise<T>): Promise<Omit<T, 'data'> & { data: SoundPackage[] }> {
    return apiPromise.then((res) => {
        return {
            ...res,
            data: mapSyncAlbumToPackage(res.data)
        }
    })
}

export function transformAlbumPackResponse<T extends { data: { packs: Album[], current: Album } }>(apiPromise: Promise<T>): Promise<Omit<T, 'data'> & { data: { packs: SoundPackage[], current: SoundPackage } }> {
    return apiPromise.then((res) => {
        return {
            ...res,
            data: {
                packs: mapSyncAlbumToPackage(res.data.packs),
                current: mapSyncAlbumToPackage([res.data.current])[0]
            }
        }
    })
}

export function mapSyncSoundToSound(sounds: SyncSound[]): Sound[] {
    return sounds.map(sound => {
        const fullVersion = getFullVersion(sound.SoundVersions)
        const hasStems = sound.SoundVersions.find(v => v.audio_type == 'stem') != undefined
        return {
            ...fullVersion,
            ...sound,
            duration: sound.full_version_duration,
            sound_package_id: sound.album_id,
            sound_package_name: sound.album_name,
            has_stream_info: true,
            has_stems: hasStems,
            is_create_sound: false,
            versions: sound.SoundVersions.filter((v) => v.audio_type == 'audio').map((v) => mapSoundVersionToPlayableSound(v, sound))
        }
    })
}

export function mapSoundVersionToPlayableSound(version: SyncSoundVersion, sound: Sound | SyncSound): PlayableSound {
    return {
        ...version,
        artist: sound.artist,
        artwork_url: sound.artwork_url
    }
}

export function mapSyncAlbumToPackage(albums: Album[]): SoundPackage[] {
    return albums.map(albums => {
        return {
            ...albums,
            is_favorited: false,
            __typename: 'SoundPackage'
        }
    })
}

export function getFullVersion(versions: SyncSoundVersion[]) {
    return versions.find(v => v.type == 'full')!
}