import React, { useState } from 'react'
import { Divider, Title } from 'bpm-sounds-generic';
import CardGrid from '../components/CardGrid';
import { MetaAPI } from 'src/api/endpoints/meta';
import { errorToString } from 'src/api';
import { Category } from 'src/api/models/category';
import { useHistory } from 'react-router-dom';

const Categories = () => {
    const widx = '264px'
    const [categories, setCategories] = useState<Category[]>()
    const history = useHistory()

    React.useEffect(() => {
        MetaAPI.getCategories().then(resp => {
            setCategories(resp)
        }).catch(err => errorToString(err))
    }, [])

    return (
        <div className="Categories">
            <Divider small />

            <Title
                titleText={'Categories'}
            />
            <Divider small />
            {
                categories &&
                <CardGrid
                    colMaxWidth={'54rem'}
                    cardGridItems={categories.map(category => {
                        return {
                            height: widx,
                            onClick: () => { history.push(`categories/${category.slug}`) },
                            info: {
                                image: category.image_url,
                                text: category.name
                            }
                        }
                    })}
                />
            }

            <Divider />
        </div>
    )
}

export default Categories