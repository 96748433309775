import { SoundPackageQueryAttributes, Filter, BPMSlider, DropdownButton, FadeAnimation } from "bpm-sounds-generic";
import { CHEVRON_DOWN_SVG } from "bpm-sounds-generic/assets";
import React, { useEffect } from "react";
import { Popper, ClickAwayListener } from "@material-ui/core";
import { AlbumQueryAttributes, SoundDisplayAttributes } from "src/api/endpoints/types";
import { MetaAPI } from "src/api/endpoints/meta";
import { Mood } from "src/api/models/mood";
import { Category } from "src/api/models/category";


export interface FilterWrapperProps {
    filter: AlbumQueryAttributes & SoundDisplayAttributes,
    onFilterUpdated: (filter: SoundPackageQueryAttributes & SoundDisplayAttributes) => void
    onSliderChange?: (min: number, max: number) => void;
    lengthMin?: number;
    lengthMax?: number;
}

const SyncManagedFilter = (props: FilterWrapperProps) => {
    const { filter } = props
    const setFilter = ((cb: (prevState: typeof filter) => typeof filter) => {
        props.onFilterUpdated(cb(filter))
    })
    const [showLength, setShowLength] = React.useState(false)
    const lengthDropdown = React.useRef<HTMLDivElement>(null)

    const [categories, setCategories] = React.useState<Category[]>([])
    const [moods, setMoods] = React.useState<Mood[]>([])



    useEffect(() => {
        MetaAPI.getMoods().then(resp => {
            setMoods(resp)
        })
        MetaAPI.getCategories().then(resp => {
            setCategories(resp)
        })
    }, [])

    //^ RENDER SYNC SPECIFIC ITEMS 
    const renderLengthSlider = () => {
        return <>
            <div className="slider-container">
                <DropdownButton ref={lengthDropdown}
                    buttonText={"Length"}
                    width={"100%"}
                    className={(props.lengthMin != undefined && props.lengthMin != 0 || props.lengthMax != undefined && props.lengthMax != 200) ? 'has-filter' : ''}
                    buttonImageRight={CHEVRON_DOWN_SVG}
                    buttonImageWidth={"10"}
                    open={showLength}
                    onClick={() => {
                        setShowLength(true)
                    }}
                />
                <Popper
                    anchorEl={lengthDropdown.current}
                    open={showLength}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'window',
                        }
                    }}
                    transition
                    style={{ zIndex: 300, pointerEvents: showLength ? 'auto' : 'none' }}>
                    {(transitionProps: any) => {
                        return <FadeAnimation {...transitionProps.TransitionProps!}>
                            <ClickAwayListener mouseEvent={showLength ? 'onClick' : false} onClickAway={() => {
                                setShowLength(false)
                            }}>
                                <div className={'complex-dropddown ' + (showLength ? "slide-down" : "slide-up")}>
                                    <BPMSlider
                                        onSliderChange={props.onSliderChange}
                                        sliderMin={props.lengthMin}
                                        sliderMax={props.lengthMax}
                                    />
                                </div>
                            </ClickAwayListener>
                        </FadeAnimation>
                    }}</Popper>
            </div>
        </>
    }




    const renderMoods = () => {
        return <>
            <DropdownButton
                buttonText={"Moods"}
                width={"100%"}
                className={((filter.moods && filter.moods.length)) ? 'has-filter' : ''}
                buttonImageRight={CHEVRON_DOWN_SVG}
                buttonImageWidth={"10"}
                menuItems={moods.map(mood => {
                    return {
                        text: mood.name,
                        selected: filter?.moods?.map(m => m.toLowerCase()).includes(mood.name.toLowerCase()),
                        onClick: () => {
                            let existstingMoods = (filter.moods || []).map(m => m.toLowerCase())
                            if (existstingMoods.includes(mood.name.toLowerCase())) {
                                existstingMoods = existstingMoods.filter(m => m != mood.name.toLowerCase())
                            } else {
                                existstingMoods.push(mood.name.toLowerCase())
                            }
                            setFilter((filter) => {
                                return { ...filter, moods: existstingMoods }
                            })
                        }
                    }
                })}
            />
        </>
    }

    const renderCategories = () => {
        return <>
            <DropdownButton
                buttonText={"Categories"}
                width={"100%"}
                className={((filter.categories && filter.categories.length)) ? 'has-filter' : ''}
                buttonImageRight={CHEVRON_DOWN_SVG}
                buttonImageWidth={"10"}
                menuItems={categories.map(category => {
                    return {
                        text: category.name,
                        selected: filter?.categories?.map(m => m.toLowerCase()).includes(category.name.toLowerCase()),
                        onClick: () => {
                            let existstingCategories = (filter.categories || []).map(c => c.toLowerCase())
                            if (existstingCategories.includes(category.name.toLowerCase())) {
                                existstingCategories = existstingCategories.filter(c => c != category.name.toLowerCase())
                            } else {
                                existstingCategories.push(category.name.toLowerCase())
                            }
                            setFilter((filter) => {
                                return { ...filter, categories: existstingCategories }
                            })
                        }
                    }
                })}
            />
        </>
    }

    return (<Filter
        renderFilterItems={(itemProps, defaultRenderer) => {
            return <>
                {defaultRenderer.renderNewest()}
                {defaultRenderer.renderGenres()}
                {renderMoods()}
                {renderCategories()}
                {defaultRenderer.renderBPMSlider()}
                {renderLengthSlider()}
            </>
        }
        }
        filter={filter}
        tags={[]}
        onReset={() => {
            setFilter(() => {
                return {}
            })
        }} showHalfTime={filter.showHalfTime} onHalfTimeChange={(value) => {
            setFilter((filter) => {
                return { ...filter, showHalfTime: value }
            })
        }} sortBy={filter.sort_by} onSortByChange={(sortBy) => {
            setFilter((filter) => {
                return { ...filter, sort_by: sortBy }
            })
        }} selectedTags={filter.tags} onTagClick={(tag) => {
            let currentTags = [...filter.tags || []]
            let tags = Array.isArray(tag) ? tag : [tag]
            tags.forEach(tag => {
                if (currentTags.includes(tag.id) || currentTags.includesLowercase(tag.name)) {
                    currentTags = currentTags.filter(t => !(t == tag.id || t.toLowerCase() == tag.name.toLowerCase()))
                } else {
                    currentTags.push(tag.name)
                }
            })
            setFilter((filter) => {
                return { ...filter, tags: currentTags }
            })
        }} selectedTagGroup={filter.tag_groups} onTagGroupClick={(group) => {
            let currentTags = [...filter.tag_groups || []]
            if (currentTags.includes(group.id) || currentTags.includesLowercase(group.name)) {
                currentTags = currentTags.filter(t => !(t == group.id || t.toLowerCase() == group.name.toLowerCase()))
            } else {
                currentTags.push(group.name)
            }
            setFilter((filter) => {
                return { ...filter, tag_groups: currentTags }
            })
        }} selectedGenre={filter.genre} onGenreClick={(genre) => {
            setFilter((filter) => {
                if (filter.genre && filter.genre == genre.name) {
                    return { ...filter, genre: undefined, subgenre: [] }
                }
                return { ...filter, genre: genre.name, subgenre: [] }
            })
        }} selectedSubGenres={filter.subgenre} onSubGenreClick={(subgenre) => {
            setFilter((filter) => {
                if (filter.subgenre && filter.subgenre.includesLowercase(subgenre.name)) {
                    return {
                        ...filter, subgenre: filter.subgenre.filter(g => {
                            return g.toLowerCase() != subgenre.name.toLowerCase()
                        }), genre: undefined
                    }
                } else
                    return { ...filter, subgenre: (filter.subgenre || []).concat(subgenre.name), genre: undefined }
            })
        }} bpmMax={filter.bpm_max as number} bpmMin={filter.bpm_min as number} onBPMChange={(min, max) => {
            setFilter((filter) => {
                return { ...filter, bpm_max: max, bpm_min: min }
            })
        }} />)
}
export default SyncManagedFilter