import { AnalyticsAPI, Session } from "bpm-client-api"
import { APIVersion } from "bpm-client-api/lib/requests/request"

export class Analytics {

    static events: { click: Array<Object>, view: Array<Object>, session: Array<Object> } =
        { click: [], view: [], session: [] }

    static initialize() {
        setInterval(() => {
            Analytics.send()
        }, 3000)
        Analytics.trackSession('start')
        window.onbeforeunload = function () {
            Analytics.trackSession('end')
            Analytics.send()
        }
    }

    static send() {
        if (Analytics.events.click.length > 0 || Analytics.events.view.length || Analytics.events.session.length) {
            if (typeof navigator.sendBeacon === 'function') {
                navigator.sendBeacon(`${Session.getAPIURL()}/${APIVersion.v3_0}/analytics/track/create`, JSON.stringify({ events: Analytics.events.click.concat(Analytics.events.view).concat(Analytics.events.session) }))
            } else {
                AnalyticsAPI.track(Analytics.events.click.concat(Analytics.events.view).concat(Analytics.events.session), 'sync')
            }
            Analytics.events.session = []
            Analytics.events.view = []
            Analytics.events.click = []
        }
    }

    static trackClick(entity_name: string, entity_id: string, meta: any = {}) {
        Analytics.events.click.push({
            date: new Date(),
            type: 'click',
            entity_name,
            entity_id,
            from: window.location.pathname,
            meta
        })
    }

    static trackView(entity_name?: string, entity_id?: string) {
        Analytics.events.click.push({
            date: new Date(),
            type: 'view',
            entity_name,
            entity_id,
            from: window.location.pathname
        })
    }

    static trackSession(session_type: 'end' | 'start') {
        Analytics.events.session.push({
            date: new Date(),
            type: 'session',
            session_type,
            from: window.location.pathname,
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            agent: navigator.userAgent
        })
    }
}