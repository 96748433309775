import React, { useContext, FormEvent, useRef } from 'react';
import { Button, FAQ } from 'bpm-sounds-generic';
import { UserContext } from "bpm-sounds-generic";
import { UsersAPI, APIErrorType, APIError } from 'bpm-client-api';
import { Address } from 'bpm-client-api/lib/endpoints/user';
import { Loader } from 'bpm-sounds-generic';
import { Session, errorToString } from '../../api';
import * as toastr from 'toastr'
import { EDIT_PICTURE_SVG, USER_PLACEHOLDER } from "bpm-sounds-generic/assets";
import AccountFooter from '../../components/AccountFooter';
import * as EmailValidator from 'email-validator';
import { verify } from 'crypto';
import { FAQItems } from './faq';

const Account = () => {

    const user = useContext(UserContext)
    const [address, _setAddress] = React.useState<Address>()
    const [firstname, setFirstname] = React.useState<string>('')
    const [lastname, setLastname] = React.useState<string>('')
    const [phone, setPhone] = React.useState<string>('')
    const [email, setEmail] = React.useState(user.email!)
    const [uploading, setUploading] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [username, setUsername] = React.useState(user.username);

    const [password, setPassword] = React.useState<string>('')
    const [oldPassword, setOldPassword] = React.useState<string>('')
    const [verifyPassword, setVerifyPassword] = React.useState('')

    const [userUpdating, setUserUpdating] = React.useState(false)
    const [pwUpdating, setPWUpdating] = React.useState(false)

    const fileInput = useRef<HTMLInputElement>(null);

    const validateSave = () => {
        if (firstname.trim() === '') return 'Please enter a first name'
        else if (lastname.trim() === '') return 'Please enter a a last name'
        else if (EmailValidator.validate(email) === false) return 'Please enter a valid email'
        else if (username.trim() === '') return 'Please enter a valid username'
        else return
    }

    const validatePassword = () => {
        if (oldPassword.trim() === '') return 'Please enter your old password'
        else if (password.trim() === '') return 'Please enter your new password'
        else if (verifyPassword.trim() === '') return 'Please confirm your new password'
        else if (password !== verifyPassword) return 'Passwords do not match'
        else if (oldPassword === verifyPassword) return `New password cannot be your old password`
        else return
    }

    const setAddress = (address: Address) => {
        _setAddress(address)
        setFirstname(address.first_name)
        setLastname(address.last_name)
        setPhone(address.phone || '')
    }

    React.useEffect(() => {
        UsersAPI.getAddress().then(address => {
            setAddress(address)
        })
    }, [])

    const updateUser = () => {
        setUserUpdating(true)
        Promise.all([
            UsersAPI.updateMe({ email, username }).then((user) => {
                Session.getUserUpdatedHandler()(user)
            }).catch(err => {
                toastr.error(errorToString(err).join('\n'))
            }),
            UsersAPI.setAddress({
                ...address,
                first_name: firstname!,
                last_name: lastname!,
                phone: phone
            }).then((address) => {
                setAddress(address)
            }).catch(err => {
                toastr.error(errorToString(err).join('\n'))
            })
        ]).then(() => {
            setUserUpdating(false)
            toastr.success('Successfully updated your information')
        })
    }

    const updatePassword = () => {
        setPWUpdating(true)
        UsersAPI.changePassword(
            oldPassword,
            password
        ).then(() => {
            toastr.success('Successfully updated your password')
            setOldPassword('')
            setPassword('')
            setVerifyPassword('')
        }).catch((err: APIError) => {
            if (err.type == APIErrorType.InvalidLogin) {
                toastr.error('Your old password is not correct')
            } else
                errorToString(err).forEach((message) => {
                    toastr.error(message)
                })
        }).finally(() => {
            setPWUpdating(false)
        })
    }

    return (
        <div className="Account">
            <div className="top">
                <div className="profile-container">
                    <span className="header">Profile Picture</span>
                    <div className="picture">
                        {uploading && <span className="profile-image"><Loader /></span>}
                        {!uploading && <>
                            {user.profile_image_thumbnail_url ?
                                <img className="profile-image" alt="profile-img" src={user.profile_image_thumbnail_url} onClick={() => {
                                    fileInput.current &&
                                        fileInput.current.click()
                                }} />
                                :
                                <img className="profile-image" onClick={() => {
                                    fileInput.current &&
                                        fileInput.current.click()
                                }}
                                    src={USER_PLACEHOLDER}
                                    alt="Your avatar"
                                />
                            }
                        </>}
                        <img src={EDIT_PICTURE_SVG} alt="edit upload" className="edit-icon" />
                    </div>
                </div>

                <form>
                    <div className="header account">Account</div>
                    <label htmlFor="name">First Name</label>
                    <input name="first_name" type="text" required onChange={(e) => setFirstname(e.target.value)} value={firstname} />
                    <label htmlFor="name">Last Name</label>
                    <input name="last_name" type="text" required onChange={(e) => setLastname(e.target.value)} value={lastname} />
                    <label htmlFor="name">Email</label>
                    <input id="email" type="email" required defaultValue={user.email} onChange={(e) => setEmail(e.target.value)} value={email} />
                    <label htmlFor="name">Username</label>
                    <input id="username" type="text" required defaultValue={user.username} onChange={(e) => setUsername(e.target.value)} value={username} />
                    <label htmlFor="phone">Phone number</label>
                    <input id="phone" name="phone" type="text" onChange={(e) => setPhone(e.target.value)} value={phone} />
                    <Button
                        className="save-btn"
                        buttonText={'Save'}
                        loading={userUpdating}
                        onClick={() => {
                            if (!validateSave()) {
                                updateUser()
                            }
                        }}
                        tooltipError={validateSave()}
                        hoverEffect={true}
                    />
                </form>
                <form>
                    <div className="header changepw">Change Password</div>

                    <label htmlFor="new-password">Old Password</label>
                    <input id="new-password" name="new-password" type="password" onChange={(e) => setOldPassword(e.target.value)} value={oldPassword}
                        required />
                    <label htmlFor="new-password">New Password</label>
                    <input id="new-password" name="new-password" type="password" onChange={(e) => setPassword(e.target.value)} value={password}
                        required />
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input id="confirm-password" name="confirm-password" type="password" onChange={(e) => setVerifyPassword(e.target.value)} value={verifyPassword}
                        required />
                    <Button
                        className="change-btn"
                        buttonText={'Change'}
                        loading={pwUpdating}
                        onClick={() => {
                            if (!validatePassword()) {
                                updatePassword()
                            }
                        }}
                        tooltipError={validatePassword()}
                        hoverEffect={true}
                    />
                </form>
            </div>
            <FAQ FAQItems={FAQItems} />
            <AccountFooter />

            {/* Hidden input for Profile pic Upload */}
            <input ref={fileInput} accept='image/*' type='file'
                style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute', top: -5 }} onChange={(e) => {
                    let file = e.target.files!.length > 0 ? e.target.files![0] : undefined
                    if (file) {
                        setUploading(true)
                        UsersAPI.uploadProfilePicture(file).then((user) => {
                            setUploading(false)
                            toastr.success('Profile image uploaded!')
                            Session.getUserUpdatedHandler()(user)
                        }).catch(err => {
                            setUploading(false)
                            errorToString(err).forEach(error => {
                                toastr.error(error)
                            })
                        })
                    }
                    e.target.value = ''
                }} />
        </div>
    )
}

export default Account;