import { BannerRenderer, DefaultProps, Divider, ManagedAlbumRow, SoundDisplayAttributes } from 'bpm-sounds-generic'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { SoundAPI } from '../api/endpoints/sound'
import { AlbumQueryAttributes } from '../api/endpoints/types'
import { AlbumAPI } from '../api/endpoints/album'
import SyncManagedSoundlist from '../components/SyncManagedSoundlist'
import { Analytics } from '../manager/analyticsmanager'

const NewSongs: React.FC<RouteComponentProps<{ id: string }> & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<AlbumQueryAttributes & SoundDisplayAttributes>({})
    return (
        <>
            <Divider />
            <SyncManagedSoundlist
                {...props}
                title={"New Songs"}
                apiFunc={SoundAPI.getSounds}
                filter={filter}
                context={'new songs'}
                onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }}
            />
        </>
    )
}

export default NewSongs