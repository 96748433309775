import React from 'react';

export const FAQItems = [
    {
        question: <>What forms of payment do you accept?</>,
        answer: <>We accept all major credit cards such as Visa, Mastercard, and Discover, as well as payment via PayPal.</>,
    },
    {
        question: <>On what date will I be charged again with an active subscription? </>,
        answer: <>
            Your membership subscription automatically renews every month from your initial date of sign up. For example: If you signed up on March 15th, your next billing date would be April 15th.
            <> <br></br><br></br > </>
    Note payments are automatic, should you wish to avoid future charges make sure to cancel at least 72 hours prior to your billing date to ensure no funds will be applied to your account. Automatic payments can begin to process up to 48 hours before your billing date so it is imperative the subscription is canceled within that 72 - hour window.
            <> <br></br><br></br > </>
    No refunds will be issued once payment has been applied to your account.
            <> <br></br><br></br > </>
            < a href="https://www.bpmsupreme.com/refund-policy" >
                https://www.bpmsupreme.com/refund-policy
    </a>
        </>,
    },
    {
        question: <>How do promotions work? </>,
        answer: <>Each promotion will vary. Please note promotions do not roll over onto future billing. The promotion will only be applied to the first billing cycle. Note any upgrades to the Premium membership are subject to an upgrade fee. If you have questions about a specific promo code please email < a href="mailto:info@bpmsupreme.com?subject=Contact" > info@bpmsupreme.com</a> for more details on your code or review the Terms of Use.</ >,
    },
    {
        question: <>How do I update my payment method and/or membership? </>,
        answer: <>
            The best way to change your credit card information or change memberships is by canceling your current membership.We recommend canceling today to ensure no payment is applied, you will still have access to your account for the < b > full month </b> you paid for.
            <> <br></br><br></br > </>

    We advise canceling at least 72 hours prior to your next billing date to ensure no funds are applied to your account.Recurring payments can begin to process 48 hours earlier than the billing date so canceling 72 hours prior to the billing date will ensure the subscription is canceled in a timely manner.
            <> <br></br><br></br > </>

    Once your account has expired, log into your account and you will be asked to renew your account. When you renew your account, you can then choose the membership you would like and enter your new billing information.You can change your payment method in your account under:
            <><br></br><br></br > </>
            < ul >
                <li>My account </li>
                < li > Membership info </li>
                < li > Update my plan </li>
            </ul>
    If you have any issue or questions please contact BPM for assistance.
        </>,
    },
    {
        question: <>How do I cancel my membership? </>,
        answer: <>
            Members can cancel anytime.No contract or commitment.Please ensure that you cancel your account < b > 72 hours </b> before your next payment date. If you don't, then your account will be charged for the next month. BPM Supreme does not offer refunds when this occurs, but you will be able to access your account for the full month you paid for.
            <> <br></br><br></br > </>

    When you cancel your membership before your next payment is due, you still have access until the current month cycle ends from your original payment date.
            <> <br></br><br></br > </>
            < a href="https://bpmsupreme.com/refund-policy" > REFUND POLICY </a>
        </>,
    }
];