import { CuratedPackage, DefaultProps, Sound, SoundPackage, SoundPackageQueryAttributes } from 'bpm-sounds-generic';
import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import Browse from './pages/Browse';
import Categories from './pages/Categories';
import Collections from './pages/Collections';
import Favorites from './pages/Favorites';
import Genres from './pages/Genres';
import Moods from './pages/Moods';
import NewAlbums from './pages/NewAlbums';
import { SoundDownloadManager, CuratedPackageManager, SoundManager } from 'bpm-sounds-generic';
import { AlbumAPI } from './api/endpoints/album';
import { SoundAPI } from './api/endpoints/sound';
import { CuratedAPI } from './api/endpoints/curated';
import Testing from './pages/Testing';
import NewSongs from './pages/NewSongs';
import IndividualAlbum from './pages/IndividualAlbum';
import MyDownloads from './pages/MyDownloads';
import CollectionDetail from './pages/CollectionDetail';
import MoodDetail from './pages/MoodDetail';
import Pricing from './pages/Pricing';
import CategoryDetail from './pages/CategoryDetail';
import Search from './pages/Search';
import GenreDetail from './pages/GenreDetail';


function downloadMultiSounds(apiCall: Promise<{ url: string }>): Promise<boolean> {
    const toast = toastr.info('Preparing download...', undefined, { timeOut: 0, tapToDismiss: false, extendedTimeOut: 0 })
    return apiCall.then(({ url }) => {
        toastr.remove(toast)
        window.location.assign(url)
        return true
    }).catch((err) => {
        toastr.remove(toast)
        return false
    })
}
class Main extends React.Component<RouteComponentProps & { filter: SoundPackageQueryAttributes }> {
    deafultProps: DefaultProps = {

        onClickReroute: (location) => {
            this.props.history.push(location)
        },
        onSoundPackageClick: (sound) => {
            this.props.history.push('/album/' + sound.slug)
        },
        onCuratedClick: (curated) => {
            this.props.history.push('/curated/' + curated.slug)
        },
        onSoundClick: (sound) => {
            this.props.history.push('/albums/' + sound.sound_package_id)
        },
        onTagClick: (tag) => {
            this.props.history.push('/search', { tags: [tag.name] } as SoundPackageQueryAttributes)
        },
        onTagGroupClick: (tag) => {
            this.props.history.push('/search', { tag_groups: [tag.name] } as SoundPackageQueryAttributes)
        },
        onBPMClick: (bpm) => {
            this.props.history.push('/search', { bpm_max: bpm, bpm_min: bpm } as SoundPackageQueryAttributes)
        },
        onKeyClick: (key) => {
            this.props.history.push('/search', { key } as SoundPackageQueryAttributes)
        },
        onGenreClick: (genre) => {
            this.props.history.push('/genre/' + encodeURIComponent(genre.name))
        },
        onSubGenreClick: (subGenre) => {
            this.props.history.push('/search', { subgenre: [subGenre.name] } as SoundPackageQueryAttributes)
        },
        onSearchChange: (search: string) => {
            this.props.history.push('/search', { search } as SoundPackageQueryAttributes)
        },
        onSoundLikeToggled: (sound: Sound) => {
            if (!SoundManager.getInstance().getMostRecentSound(sound).is_favorited) {
                return SoundAPI.likeSound(sound).then(sound => {
                    SoundManager.getInstance().updateSound(sound)
                    return sound
                })
            } else {
                return SoundAPI.unLikeSound(sound).then(sound => {
                    SoundManager.getInstance().updateSound(sound)
                    return sound
                })
            }
        },

        onCuratedLikeToggled: (soundPackage: CuratedPackage) => {
            if (!CuratedPackageManager.getInstance().getMostRecentCuratedPackage(soundPackage).is_favorited) {
                return CuratedAPI.likeCurated(soundPackage).then(sound => {
                    CuratedPackageManager.getInstance().updateCuratedPackage(sound)
                    return sound
                })
            } else {
                return CuratedAPI.unLikeCurated(soundPackage).then(sound => {
                    CuratedPackageManager.getInstance().updateCuratedPackage(sound)
                    return sound
                })
            }
        },
        onSoundDownload: (sound: Sound) => {
            return SoundAPI.downloadSound(sound, window.location.pathname).then(async ({ url }) => {
                sound = SoundManager.getInstance().getMostRecentSound(sound)
                sound.downloaded = true
                SoundManager.getInstance().updateSound(sound)
                window.location.assign(url)
                return true
            }).catch(() => {
                return false
            })
        },
        onSoundPackageDownload: (pack: SoundPackage) => {
            return downloadMultiSounds(AlbumAPI.downloadAlbum(pack.id, window.location.pathname)).then(async (downloaded) => {
                if (downloaded) {
                    SoundManager.getInstance().setSoundsInPackageDownloaded(pack.id)
                }
                return true
            })
        },
        onCuratedDownload: (pack: CuratedPackage, midi?: boolean) => {
            return downloadMultiSounds(CuratedAPI.downloadCurated(pack, window.location.pathname)).then(async (downloaded) => {
                if (downloaded) {
                    SoundManager.getInstance().setSoundsInPackageDownloaded(pack.id)
                }
                return true
            })
        },
        onSoundDownloadMulti: (sounds: string[], midi?: boolean) => {
            return downloadMultiSounds(SoundAPI.downloadMultiSound(sounds, window.location.pathname)).then(async (downloaded) => {
                if (downloaded) {
                    SoundManager.getInstance().setSoundsDownloaded(sounds)
                    SoundDownloadManager.getInstance().clear()
                }
                return true
            })
        },
    }

    shouldComponentUpdate(nextProps: { filter: SoundPackageQueryAttributes }) {
        if (nextProps.filter != this.props.filter) {
            this.props.history.push('/search', nextProps.filter)
            return false
        }
        return true
    }

    render() {
        return (
            <div className='main-content'>
                <div className="constrain-width">
                    <Switch>
                        <Route path='/testing' render={() => {
                            return <Testing />
                        }} />
                        <Route path='/browse/' render={(props) => {
                            return <Browse {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/collections' render={() => {
                            return <Collections />
                        }} />
                        <Route path='/favorites' render={(props) => {
                            return <Favorites  {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/genres' render={() => {
                            return <Genres {...this.deafultProps} />
                        }} />
                        <Route path='/genre/:slug' render={(props) => {
                            return <GenreDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/moods' exact render={() => {
                            return <Moods />
                        }} />
                        <Route path='/moods/:slug' render={(props) => {
                            return <MoodDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/categories' exact render={() => {
                            return <Categories />
                        }} />
                        <Route path='/categories/:slug' render={(props) => {
                            return <CategoryDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/downloads' render={(props) => {
                            return <MyDownloads {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/new_albums' render={(props) => {
                            return <NewAlbums {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/newsongs' render={(props) => {
                            return <NewSongs {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/album/:id' render={(props) => {
                            return <IndividualAlbum {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/collection/:collectionId' render={(props) => {
                            return <CollectionDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/pricing' exact render={(props) => {
                            return <Pricing {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/search' render={(props) => {
                            return <Search {...props} {...this.deafultProps} />
                        }} />
                    </Switch>
                </div>
            </div>
        )
    }

}

export default withRouter(Main)
