import { SoundPackageQueryAttributes } from 'bpm-sounds-generic'
import * as H from 'history'
import * as qs from 'query-string'
import { SoundDisplayAttributes, SoundQueryAttributes } from '../api/endpoints/types'

export function transferSearchToQuery(history: H.History, object: SoundQueryAttributes & SoundDisplayAttributes) {
    history.replace({
        search: qs.stringify(object)
    })
}

export function parseSearchFromQuery(location: H.Location): SoundQueryAttributes & SoundDisplayAttributes & SoundPackageQueryAttributes {
    let search = qs.parse(location.search) as SoundQueryAttributes & SoundDisplayAttributes & SoundPackageQueryAttributes
    if (typeof search.tag_groups == 'string') {
        search.tag_groups = [search.tag_groups]
    }
    if (typeof search.tags == 'string') {
        search.tags = [search.tags]
    }
    if (typeof search.subgenre == 'string') {
        search.subgenre = [search.subgenre]
    }
    if (typeof search.artist == 'string') {
        search.artist = [search.artist]
    }
    if (typeof search.label == 'string') {
        search.label = [search.label]
    }
    return search
}