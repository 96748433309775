import React, { useState, useEffect } from "react";
import { Switch, Route } from 'react-router-dom';
import { Redirect, Router, useHistory, useLocation } from 'react-router';
import { Popup, DriveContextProvider, UserContextProvider, HeaderBar, Sidebar, Loader, PlayerContextProvider, PlayerState, PlaybackState, Player, PlaybackManager, SoundDownloadManager, CreditInfo, Drive } from 'bpm-sounds-generic';
import { Environment } from "./env";
import { CollectionManager, COLLECTION_LOADED_EVENT } from "./manager/collectionmanager";
import { UsersAPI } from "./api/endpoints/user";
import { Collection } from "./api/models/collection";
import { UsersAPI as BPMUsersAPI, SubscriptionInfo } from 'bpm-client-api'
import { SoundPackageQueryAttributes } from "bpm-sounds-generic";
import Main from './Main'
import { SYNC_ICON, SYNC_LOGO, SYNC_LOGO_DARK } from "./assets";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { errorToString, Session } from "./api";
import { User } from "./api/models/user";
import { APIError } from "./api/requests/request";
import { SUGGESTIONS_SVG } from "bpm-sounds-generic/assets";
import Account from "./pages/account/Account";
import AccountHeader from "./components/AccountHeader";
import Billing from "./pages/account/Billing";
import Subscribe from "./pages/account/Subscribe";

const App = () => {
    const [theme, setTheme] = useState<'dark' | 'light'>("dark");
    const [soundsToDownloads, setSoundsToDownloads] = React.useState<string[]>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const [collections, setCollections] = useState<Drive[]>([])
    const [search, setSearch] = useState<SoundPackageQueryAttributes>({});
    const [sidebarExpanded, setSidebarExpanded] = useState(true)
    const [user, setUser] = useState<User>();
    const [playerState, setPlayerState] = React.useState<PlaybackState>({ state: PlayerState.None })
    const history = useHistory();
    const location = useLocation()

    const newSidebarState = (newState: boolean) => {
        setSidebarExpanded(newState)
    }

    Session.setUnauthorizedHandler((err) => {

        window.location.assign(Environment.BPM_URL + '/login?platform=sync&pool_redirect=' + window.location.pathname.split('/').map(encodeURIComponent).join('/'));
    })

    Session.setSubscriptionHandler((err) => {
        if (err.current_supscription > SubscriptionInfo.None) {
            if (err.current_supscription == SubscriptionInfo.Pro) {
                return Popup.driveLimit()
            }
            Popup.upgrade().then(result => {
                if (result == 'ok') {
                    window.location.assign('/pricing')
                } else {
                }
            })

        } else {
            Popup.subscribe().then(result => {
                if (result == 'ok') {
                    window.location.assign('/pricing')
                } else {

                }
            })

        }
    })
    Session.setUserUpdatedHandler((user) => {
        setUser(user)
        setTheme(user.theme == 'black' ? 'dark' : 'light')
    })

    const loadUser = () => {
        UsersAPI.getMe().then(user => {
            if (window.bugsnagClient) {
                window.bugsnagClient.setUser(user.id.toString(), user.email, user.full_name)
            }
            if (user.is_admin) {
                if (localStorage['edit_mode'] == 'true') {
                    setEditMode(true)
                }
                jiraHelpdesk(function () {
                    var DOMContentLoaded_event = document.createEvent('Event');
                    DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
                    window.document.dispatchEvent(DOMContentLoaded_event);
                });
            }
        }).catch((err: APIError) => {
            if (err.status == 429) {    // API Request Limit Reached, wait and reload
                setTimeout(() => {
                    loadUser()
                }, 5100)
            }
        })
    }

    useEffect(() => {
        PlaybackManager.getInstance().addPlaybackStateListener((state: PlaybackState) => {
            setPlayerState(state)
        })
        loadUser()
    }, [])


    useEffect(() => {
        CollectionManager.instance().on(COLLECTION_LOADED_EVENT, (collections: Collection[]) => {
            setCollections(collections.slice())
        })
        CollectionManager.instance().loadCollections()

        SoundDownloadManager.getInstance().addSoundStateListener((sounds) => {
            setSoundsToDownloads(sounds.map(s => s.id))
        })
    }, [])

    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.remove("light-theme");
            document.body.classList.add("dark-theme");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.add("light-theme");
        }
    }, [theme])

    const onThemeToggle = () => {
        UsersAPI.updateTheme(theme === 'dark' ? 'white' : 'black').then((newUser) => {
            user!.theme = theme === 'dark' ? 'white' : 'black'
            setUser({ ...user! });
        }).catch((err) => { errorToString(err) })
        theme === "dark" ? setTheme("light") : setTheme("dark");
    };


    const onEditToggle = () => {
        localStorage['edit_mode'] = !editMode
        setEditMode(!editMode)
    }


    const onLogout = () => {
        BPMUsersAPI.logout().catch(() => { }).then(() => {
            window.location.assign(Environment.BPM_URL + '/sync/')
        })
    }

    const renderHeader = () => {
        return (

            <HeaderBar
                onThemeToggle={onThemeToggle} onEditToggle={onEditToggle} theme={theme}
                onFilterChange={(filter) => setSearch(filter)}
                navItems={navItems}
                onLogout={onLogout}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={newSidebarState}
                urls={{
                    supreme: Environment.BPM_APP_URL,
                    create: Environment.POOL_URL,
                    latino: Environment.LATINO_APP_URL,
                }}
                platform={{
                    url: Environment.SYNC_APP_URL,
                    logo_dark: SYNC_LOGO_DARK,
                    logo_light: SYNC_LOGO,
                    icon: SYNC_ICON
                }}
            />
        )
    }

    const renderSidebar = () => {
        return (
            <Sidebar
                navItems={navItems}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={newSidebarState}
                onNavigateToDrive={(collection: Collection) => {
                    history.push(`/collection/` + collection.id)
                }}
            />
        )
    }

    if (!user) {
        return <Loader centered style={{ marginTop: '10%' }} />
    }


    const navItems = [
        {
            category_name: 'Main Pages',
            id: 'browse-header',
            items: [
                {
                    text: 'Browse',
                    path: '/browse',
                    altText: 'Browse',
                },
                {
                    text: 'New Songs',
                    path: '/newsongs',
                    altText: 'New Songs',
                },
                {
                    text: 'New Albums',
                    path: '/new_albums',
                    altText: 'New Albums',
                },
                {
                    text: 'Categories',
                    path: '/categories',
                    altText: 'Categories',
                },
                {
                    text: 'Genres',
                    path: '/genres',
                    altText: 'Genres',
                },
                {
                    text: 'Moods',
                    path: '/moods',
                    altText: 'Moods',
                }
            ]
        },
        {
            category_name: 'My Music',
            items: [
                {
                    text: 'My Downloads',
                    path: '/downloads',
                    altText: 'My Downloads',
                },
                {
                    text: 'Favorites',
                    path: '/favorites',
                    altText: 'Favorite',
                },
            ]
        },
        {
            category_name: 'Collections',
            items:
                collections.map((collection) => {
                    return (
                        {
                            text: collection.name,
                            path: `/collection/${collection.id}`,
                            iconImg: SUGGESTIONS_SVG,
                            altText: collection.name
                        }
                    )
                })
        },
    ]


    return (
        <>
            <UserContextProvider value={user}>
                <DndProvider backend={HTML5Backend}>
                    <DriveContextProvider value={collections}>
                        <Router history={history}>
                            <Switch>

                                <Route path='/account' render={(props) => {
                                    return (
                                        <>
                                            <AccountHeader onLogout={onLogout} defaultSelected='account' />
                                            <Account />
                                        </>
                                    )

                                }} />
                                <Route path='/billing' render={(props) => {
                                    return (
                                        <>
                                            <AccountHeader onLogout={onLogout} defaultSelected='billing' />
                                            <Billing />
                                        </>
                                    )
                                }} />
                                <Route path='/subscribe/:packageId' render={(props) => {
                                    return (
                                        <>
                                            <AccountHeader onLogout={onLogout} defaultSelected='billing' />
                                            <Subscribe />
                                        </>
                                    )
                                }} />
                                <Route path='/:page' render={() => {
                                    return (
                                        <>
                                            {renderHeader()}
                                            {renderSidebar()}
                                            <Main filter={search} />
                                            <video id='bpmlive' autoPlay controls playsInline muted
                                                style={{
                                                    visibility: 'hidden',
                                                    top: 0, left: 0, width: 0,
                                                    height: 0, position: 'absolute'
                                                }}
                                            />
                                        </>
                                    )
                                }} />
                                <Route render={() => {
                                    return <Redirect to={'/browse'} />
                                }} />
                            </Switch>
                            <Popup />
                            <Player onRouting={(path) => {
                                history.push(path);
                            }} onSoundPackageClick={() => {

                            }} />
                        </Router>
                    </DriveContextProvider>
                </DndProvider>
            </UserContextProvider>
        </>
    )
}
export default App

function jiraHelpdesk(callback: () => void) {
    var jhdScript = document.createElement('script');
    jhdScript.type = 'text/javascript';
    jhdScript.setAttribute('data-jsd-embedded', '');
    jhdScript.setAttribute('data-key', '70fde2ad-27ef-4031-ad5a-493e97eb9c99')
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

    jhdScript.onload = function () {
        callback();
    };
    document.getElementsByTagName('head')[0].appendChild(jhdScript);
}

