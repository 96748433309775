import * as React from 'react'
import { RouteComponentProps, withRouter, useHistory, useLocation } from "react-router";
import { SoundAPI } from '../api/endpoints/sound';
import * as hash from 'object-hash'
import { DefaultProps, useDeepCompareEffect, Divider, ManagedAlbumRow, PagingInfo, PagingInfoResponse, SoundDisplayAttributes, SoundPackage, SoundPackageQueryAttributes } from "bpm-sounds-generic";
import { AlbumQueryAttributes, SoundQueryAttributes } from 'src/api/endpoints/types';
import SyncManagedSoundlist from 'src/components/SyncManagedSoundlist';
import { parseSearchFromQuery, transferSearchToQuery } from 'src/util/query-param';
// import { parseSearchFromQuery, transferSearchToQuery } from 'src/util/query-param';

const Search: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<AlbumQueryAttributes & SoundDisplayAttributes>({})
    const [albumHashFilter, setAlbumHashFilter] = React.useState(hash.MD5({ keyType: undefined, showHalfTime: undefined }))
    const history = useHistory()
    const location = useLocation<AlbumQueryAttributes>()

    React.useEffect(() => {
        setFilter(parseSearchFromQuery(location))
    }, [])

    React.useEffect(() => {
        setFilter((filter) => ({ ...filter, ...location.state }))
    }, [props.location.state])

    useDeepCompareEffect(() => {
        transferSearchToQuery(history, filter)
        setAlbumHashFilter(hash.MD5({ ...filter, keyType: undefined, showHalfTime: undefined } as AlbumQueryAttributes & SoundDisplayAttributes))
    }, [filter])

    // const soundPackageTransform = (filter: SoundPackageQueryAttributes) => {
    //     return (paging: PagingInfo, token?: string): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse, token?: string }> => {
    //         return SoundAPI.getSounds(filter, paging, token).then((data) => {
    //             return { data: data.data, pagination: data.pagination, token: data.token }
    //         })
    //     }
    // }

    return (
        <>
            <Divider small />
            {/* <ManagedAlbumRow {...props} title={'Sound Packs'} context={'search_album'} apiFunc={soundPackageTransform(filter)} hashToWatch={albumHashFilter} /> */}
            <Divider />
            <SyncManagedSoundlist {...props} context={'search'} apiFunc={SoundAPI.getSounds} pagingMode='manual'
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} />
        </>
    )
}

export default withRouter(Search)