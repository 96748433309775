import { PagingInfo, Sound, PagingInfoResponse, SoundsList, useDeepCompareEffect, onTagClick, SoundsListProps, PlayerContext, DefaultListItemRenderer, WaveForm, togglePlayPause, PlayableSound, FullPlaybackMediaInfo } from 'bpm-sounds-generic'
import React, { useEffect, useState, useRef } from 'react'
import * as uuid from 'uuid'
import * as _ from 'lodash'
import SyncManagedFilter from './SyncManagedFilter'
import { AlbumQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types'
import { SyncSound } from 'src/api/models/sound'
import { CHEVRON_WHITE_SVG } from 'bpm-sounds-generic/assets'
import { SoundListItemProps } from 'bpm-sounds-generic'
import { SyncSoundVersion } from 'src/api/models/sound_version'
import { getFullVersion } from 'src/util/transformer'

export type Filter = AlbumQueryAttributes & SoundDisplayAttributes

function hasFilter<F>(props: ManagedSoundListProps<F>): props is ManagedSoundListPropsWithFilter<F> {
    return 'filter' in props
}

interface ManagedSoundListPropsWithoutFilter<F> extends Omit<SoundsListProps<F>, 'onRefineSearchChange' | 'filter' | 'onFilterChange'> {
    apiFunc: (paging: PagingInfo, token?: string) => Promise<{ data: Sound[], pagination: PagingInfoResponse, token?: string }>
}
interface ManagedSoundListPropsWithFilter<F> extends Omit<SoundsListProps<F>, 'onRefineSearchChange'> {
    apiFunc: (filter: F, paging: PagingInfo, token?: string) => Promise<{ data: Sound[], pagination: PagingInfoResponse, token?: string }>
}

interface ManagedSoundListPropsGeneric {
    defaultLimit?: number
}

export type ManagedSoundListProps<F> = (ManagedSoundListPropsWithoutFilter<F> | ManagedSoundListPropsWithFilter<F>) & ManagedSoundListPropsGeneric

function SyncManagedSoundlist<F extends Filter>(props: ManagedSoundListProps<F>) {

    const firstDefaultFilter = useRef(hasFilter<F>(props) ? props.filter : {} as F)

    const [sounds, setSounds] = useState<{ data: Sound[], paging: PagingInfoResponse }>()
    const [expandedSound, setExpandedSound] = useState<Sound>()

    const [invisible, setInvisible] = useState(false)
    const [loading, setLoading] = useState(true)
    const [limit, setLimit] = useState(props.defaultLimit || 20)
    const [page, setPage] = useState(1)
    const [filterHeight, setFilterHeight] = useState(0)
    const [forceToggle, setForceToggle] = useState(false)
    const loadingToken = useRef<string>()
    const soundList = useRef<SoundsList<F>>(null)

    const [filter, _setFilter] = useState<F>(hasFilter<F>(props) ? props.filter : {} as F)

    const setFilter = ((cb: typeof filter | ((prevState: typeof filter) => typeof filter)) => {
        if (typeof cb === 'function') {
            _setFilter((filter) => {
                return { ...cb(filter), ...firstDefaultFilter.current }
            })
        } else {
            _setFilter((filter) => {
                return { ...filter, ...cb, ...firstDefaultFilter.current }
            })
        }
    })

    useDeepCompareEffect(() => {
        if (hasFilter<F>(props) && !_.isEqual(props.filter, filter)) {
            setFilter(props.filter)
        }
    }, hasFilter<F>(props) ? [props.filter] : [])

    useDeepCompareEffect(() => {
        if (hasFilter<F>(props)) {
            props.onFilterChange && props.onFilterChange(filter)
        }
    }, [filter])

    useDeepCompareEffect(() => {
        loadingToken.current = uuid.v4()
        setLoading(true)
        if (hasFilter<F>(props)) {
            props.apiFunc(filter, { skip: (page - 1) * limit, limit: limit }, loadingToken.current).then(({ data, pagination, token }) => {
                if (loadingToken.current == token || !token) {
                    setSounds({ data, paging: pagination })
                    setLoading(false)
                    setInvisible(false)
                }
            })
        } else {
            props.apiFunc({ skip: (page - 1) * limit, limit: limit }, loadingToken.current).then(({ data, pagination, token }) => {
                if (loadingToken.current == token || !token) {
                    setSounds({ data, paging: pagination })
                    setLoading(false)
                    setInvisible(false)
                }
            })
        }
    }, [filter, limit, page, forceToggle])

    const renderVersionsChevron = (itemProps?: SoundListItemProps) => {
        return (
            <img
                className={
                    `versions-chevron ${itemProps && itemProps.sound.versions!.length < 1 && 'hidden'}
                    ${expandedSound?.id === itemProps?.sound.id ? 'rotate' : ''}`}
                src={CHEVRON_WHITE_SVG}
                onClick={() => {
                    itemProps?.onItemExpand && itemProps.onItemExpand(itemProps.sound)
                }}
                alt="versions chevron">

            </img>
        )
    }

    const renderVersionWaveForm = (version: PlayableSound, playerContext: PlayerContext) => {
        const playlist = soundList.current!.currentPlaylist
        return <>
            <div className='wave-form-container' onClick={() => {
                togglePlayPause(playerContext, version, playlist, null);
            }}>
                <WaveForm sound={version} playlist={playlist} />
            </div>
        </>
    }

    const renderGenres = (item: SyncSound) => {
        return <> <div className="sl-genres-container">
            <span className="sl-genre">{item.Album?.Genre?.name}</span>
        </div>
        </>
    }

    return <div
        className={'SyncList ManagedSoundlist ' + (invisible ? 'temp-invisible' : '')}>
        <div
            className={filterHeight < (window.innerHeight / 2 - 68) ? 'sticky' : ''}>

            {soundList.current?.renderHeader()}

            {hasFilter(props) &&
                <SyncManagedFilter
                    filter={filter}
                    onFilterUpdated={(filter) => {
                        setFilter(filter as F)
                    }}
                />}
        </div>

        <div className='sticky-target' />

        <SoundsList
            onItemExpand={(sound) => {
                if (expandedSound?.id === sound?.id) {
                    setExpandedSound(undefined)
                }
                else setExpandedSound(sound)
            }}
            expandedItem={expandedSound}
            listItemRenderer={{
                //^ Render Header
                listItemHeaderRenderer: (playerContext: PlayerContext, defaultRenderer) => {
                    return <>
                        {renderVersionsChevron()}
                        {defaultRenderer.renderArtwork()}
                        {defaultRenderer.renderWaveform()}
                        {defaultRenderer.renderPlayButton()}
                        {defaultRenderer.renderSoundName()}
                        <div className="sl-genres-container" >
                            GENRES
                        </div>
                        {defaultRenderer.renderKeyBPMTime()}

                        <div className="icons-container">
                            {defaultRenderer.renderSimilarIcon()}
                            {defaultRenderer.renderHeartIcon()}
                            {defaultRenderer.renderDownloadIcon()}
                            {defaultRenderer.renderEllipsesIcon()}
                        </div>
                    </>
                },
                //^ Render Items
                listItemRenderer: (itemProps: SoundListItemProps, playerContext: PlayerContext, defaultRenderer: DefaultListItemRenderer) => {
                    const fullVersion = itemProps.sound.versions?.find((v => v.type == 'full'))!
                    return <>
                        {renderVersionsChevron(itemProps)}
                        {defaultRenderer.renderArtwork()}
                        {defaultRenderer.renderPlayButton(fullVersion, playerContext)}
                        {defaultRenderer.renderWaveform(fullVersion, playerContext)}
                        {defaultRenderer.renderSoundName(playerContext)}
                        {renderGenres(itemProps.sound as unknown as SyncSound)}
                        {defaultRenderer.renderKeyBPMTime()}

                        <div className="icons-container">
                            {defaultRenderer.renderDownloadMidiIcon()}
                            {defaultRenderer.renderSimilarIcon()}
                            {defaultRenderer.renderHeartIcon()}
                            {defaultRenderer.renderDownloadIcon()}
                            {defaultRenderer.renderEllipsesIcon(playerContext)}
                        </div>

                    </>
                }
            }}
            syncStyle={true}
            ref={soundList}
            sounds={sounds?.data}
            loading={loading}
            filter={filter}
            onFilterChange={hasFilter<F>(props) ? (newFilter) => {
                if (hasFilter<F>(props)) {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }
            } : undefined}
            onTagClick={(tag) => {
                onTagClick(tag, filter, setFilter)
            }}
            onBPMClick={(bpm) => {
                setFilter((filter) => {
                    return { ...filter, bpm_max: bpm, bpm_min: bpm }
                })
            }}
            paging={sounds?.paging}
            onLoadMore={(limitOrPage, force, isPaging) => {
                if (!isPaging) {
                    if (limitOrPage == limit && force) {
                        setForceToggle(!forceToggle)
                    } else {
                        setLimit(limitOrPage)
                    }
                } else {
                    setInvisible(true)
                    setPage(limitOrPage)
                }
            }}
            buildPlaylist={(playlist) => {
                const playlistSounds = sounds?.data.reduce((prev, sound) => {
                    sound.versions?.forEach((v) => prev.push(v))
                    return prev
                }, new Array<PlayableSound>()) || []
                playlist.replace((playlistSounds || []).map(sound => {
                    return ({
                        sound,
                        playlist: playlist
                    } as FullPlaybackMediaInfo);
                }));
                return playlist
            }}
            context={props.context}
            {...props as any} />
    </div>
}

export default SyncManagedSoundlist