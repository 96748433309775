import React from "react";
import { MembershipInfo, UsersAPI } from "../../api/endpoints/user";
import { UsersAPI as BPMUsersAPI, PaymentAPI, errorToString, SubscriptionInfo } from "bpm-client-api";
import { RouteComponentProps, Redirect, withRouter } from "react-router";
import { SubscriptionPackage, CouponUsageCalc, SubscriptionPackageChange } from "bpm-client-api/lib/endpoints/payment";
import * as toastr from 'toastr'
import { Button, Plan, Loader, CreditCardInput, Card } from "bpm-sounds-generic";
import * as _ from 'lodash'
import moment from 'moment'
import { getAvailablePlans } from "src/util/subscription";

const redirect = window.location.protocol + '//' + window.location.hostname + '/billing'
class Subscribe extends React.Component<RouteComponentProps<{ packageId: string }>, {
    memberShipInfo?: MembershipInfo
    trialApplicable: boolean

    loading: boolean,
    package?: SubscriptionPackage
    trial: boolean
    card?: { cardNumber: string, cardType: string }
    changeInfo?: SubscriptionPackageChange

    apiCoupon?: CouponUsageCalc
    coupon: string
    couponValid?: boolean
    selectedPaymentOption: 'existing' | 'paypal' | 'card'
    subscribing: boolean
}> {
    private creditCardForm: CreditCardInput | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            trial: false,
            coupon: '',
            selectedPaymentOption: 'card',
            subscribing: false,
            trialApplicable: false
        }

        this.loadPromoCode = _.debounce(this.loadPromoCode, 200)
    }

    componentDidMount() {
        UsersAPI.getMembership().then((info) => {
            this.setState({ memberShipInfo: info.activeSubscription, trialApplicable: info.trialApplicable, loading: false })
            if (info.activeSubscription) {
                if (this.getPack()?.type == 'downgrade' || this.getPack()?.type == 'upgrade') {
                    PaymentAPI.changeSubscriptionPreview(this.props.match.params.packageId as any).then(info => {
                        this.setState({ changeInfo: info })
                        sessionStorage['upgrade_effective_date'] = new Date().toJSON()
                    }).catch(err => {
                        errorToString(err).forEach(error => {
                            toastr.error(error)
                        })
                    })
                }
            }
        }).catch(() => {
            this.setState({ trialApplicable: true, loading: false })
        })
        BPMUsersAPI.getCreditCard('create').then(({ card }) => {
            this.setState({ card, selectedPaymentOption: 'existing' })
        })
        PaymentAPI.getPackage(parseInt(this.props.match.params.packageId), 'create').then(([info, trial]) => {
            this.setState({ package: info, trial })
        }).catch(err => {
            errorToString(err).forEach(error => {
                toastr.error(error)
            })
        })
    }

    getPack() {
        return getAvailablePlans(this.state.memberShipInfo?.membership_info, this.state.trialApplicable).find(p => p.id == parseInt(this.props.match.params.packageId))
    }

    private loadPromoCode(coupon: string) {
        if (coupon && coupon.trim() != '') {
            PaymentAPI.checkCoupon(this.props.match.params.packageId, coupon).then(({ coupon, info: apiCoupon }) => {
                if (this.state.coupon && coupon.name.toLowerCase() == this.state.coupon.toLowerCase()) {
                    if (this.state.changeInfo) {
                        PaymentAPI.changeSubscriptionPreview(this.props.match.params.packageId as any, undefined, coupon.name).then(info => {
                            this.setState({ changeInfo: info, couponValid: true, apiCoupon })
                            sessionStorage['upgrade_effective_date'] = new Date().toJSON()
                        }).catch(err => {
                            this.setState({ couponValid: false, apiCoupon: undefined })
                        })
                    } else {
                        this.setState({ couponValid: true, apiCoupon })
                    }
                }
            }).catch(err => {
                this.setState({ couponValid: false, apiCoupon: undefined })
            })
        } else {
            this.setState({ couponValid: undefined })
        }
    }

    private checkCoupon(coupon: string) {
        this.setState({ coupon, apiCoupon: undefined, couponValid: undefined })
        this.loadPromoCode(coupon)
    }

    private convertCard(card: Card) {
        return {
            expMonth: card.month,
            expYear: card.year,
            cvv: card.cvv,
            pan: card.number,
        }
    }

    private subscribe() {
        if (this.state.selectedPaymentOption == 'card') {
            if (!this.creditCardForm || !this.creditCardForm.valid()) {
                toastr.error('Please insert a valid credit card')
            } else {
                this.setState({ subscribing: true })
                const card = this.creditCardForm.getCard()
                PaymentAPI.subscribeCreditCard(this.props.match.params.packageId, this.convertCard(card), this.state.coupon || undefined, 'create').then((info) => {
                    this.onPayment()
                }).catch(err => {
                    this.setState({ subscribing: false })
                    errorToString(err).forEach(error => {
                        toastr.error(error)
                    })
                })
            }
        } else if (this.state.selectedPaymentOption == 'existing') {
            this.setState({ subscribing: true })
            PaymentAPI.subscribeCreditCard(this.props.match.params.packageId, undefined, this.state.coupon || undefined, 'create').then((info) => {
                this.onPayment()
            }).catch(err => {
                this.setState({ subscribing: false })
                errorToString(err).forEach(error => {
                    toastr.error(error)
                })
            })
        } else if (this.state.selectedPaymentOption == 'paypal') {
            this.setState({ subscribing: true })
            PaymentAPI.subscribePaypal(this.props.match.params.packageId, redirect, this.state.coupon || undefined, 'create').then((info) => {
                window.location.assign(info.redirect)
            }).catch(err => {
                this.setState({ subscribing: false })
                errorToString(err).forEach(error => {
                    toastr.error(error)
                })
            })
        }
    }

    private changeSubscription() {
        if (this.state.changeInfo?.new_cc_required) {
            if (!this.creditCardForm || !this.creditCardForm.valid()) {
                toastr.error('Please insert a valid credit card')
                return
            }
        }
        this.setState({ subscribing: true })
        PaymentAPI.changeSubscription(
            this.props.match.params.packageId as unknown as number,
            new Date(sessionStorage['upgrade_effective_date']),
            (this.state.changeInfo?.new_cc_required) ? this.convertCard(this.creditCardForm!.getCard()) : { redirect_base: redirect }
        ).then((info) => {
            if (info.redirect) {
                window.location.assign(info.redirect)
            } else {
                this.onPayment()
            }
        }).catch(err => {
            this.setState({ subscribing: false })
            errorToString(err).forEach(error => {
                toastr.error(error)
            })
        })
    }

    private onPayment() {
        this.props.history.push('/billing?subscribed')
    }

    private renderOption(plan: Plan) {
        switch (plan.type) {
            case 'downgrade':
                return <>This option will <span className="blue-text">downgrade</span> your current plan</>
            case 'subscribe':
                return <>This option will <span className="blue-text">subscribe</span> to a new plan</>
            case 'upgrade':
                return <>This option will <span className="blue-text">upgrade</span> your current plan</>
            case 'one-time':
                return <>This option will <span className="blue-text">add additional credits</span> to your current plan</>
        }
    }

    private renderOptionDescription(plan: Plan) {
        switch (plan.type) {
            case 'upgrade':
                return <>If you upgrade your subscription plan, the new plan will take{'\n'} effect immediately once your payment has been processed.</>;
            case 'downgrade':
                return <>If you downgrade your subscription plan, the new plan will take{'\n'} effect on {new Date(this.state.memberShipInfo!.next_billing!).toLocaleDateString()}.</>;
            case 'one-time':
                return <>Your credits will be added to your account once your payment<br />has been processed and are valid until {new Date(this.state.memberShipInfo!.next_billing!).toLocaleDateString()}</>
            default:
                return <></>
        }
    }

    private renderAfterTrialText() {
        if (this.state.trial && this.state.package) {
            return 'starting ' + moment().add(this.state.package.trial_period, 'months').toDate().toLocaleDateString()
        } else if (this.state.memberShipInfo &&
            (this.state.memberShipInfo.membership_info > SubscriptionInfo.None && this.state.changeInfo && this.state.changeInfo!.start_date)) {
            return 'starting ' + new Date(this.state.changeInfo!.start_date).toLocaleDateString()
        }
        return ''
    }

    renderCoupon() {
        if (this.state.package?.instructions?.includes('no-promocode')) {
            return null
        }
        return <>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Coupon:
                    </div>
                    <div className="text margin">
                        <input className={(this.state.couponValid === true ? 'valid' : (this.state.couponValid === false ? 'invalid' : ''))} type='text' value={this.state.coupon} onChange={(e) => this.checkCoupon(e.target.value)} />
                    </div>
                </div>
            </div>
            {this.state.apiCoupon && ['percentage_off', 'dollar_off'].includes(this.state.apiCoupon.type) && <div className="content-row">
                <div>
                    <div className='content-head'>
                        Applied Promo Code:
                    </div>
                    <div className="text">
                        ${this.state.apiCoupon.off_amount} off
                    </div>
                </div>
            </div>}
            {this.state.apiCoupon && ['percentage_off', 'dollar_off'].includes(this.state.apiCoupon.type) && <div className="content-row">
                <div>
                    <div className='content-head'>
                        Final Amount:
                    </div>
                    <div className="text">
                        ${this.state.apiCoupon.final_amount}
                    </div>
                </div>
            </div>}
            {this.state.apiCoupon && ['promo_credits', 'regular_credits'].includes(this.state.apiCoupon.type) && <div className="content-row">
                <div>
                    <div className='content-head'>
                        Additional {this.state.apiCoupon.type == 'promo_credits' ? 'Promo' : ''} Credits:
                    </div>
                    <div className="text">
                        {this.state.apiCoupon.value}
                    </div>
                </div>
            </div>}
        </>
    }

    renderCreditCard() {
        return <CreditCardInput ref={(ref) => {
            this.creditCardForm = ref
        }} />
    }

    renderPaymentMethods(onlyCreditcard?: boolean) {
        return <div className="content-row">
            <div>
                <div className='content-head'>
                    Payment Method:
                </div>
                <div className="text payment-method__container margin">

                    <div className="payment-method__item">
                        <input id="payment-form-method-card" name="method" type="radio" value="card" checked={this.state.selectedPaymentOption == 'card'} onChange={(e) => {
                            this.setState({ selectedPaymentOption: e.currentTarget.checked ? 'card' : 'paypal' })
                        }} />
                        <label htmlFor="payment-form-method-card">Credit Card</label>
                    </div>
                    <div className="payment-method__item">
                        {this.state.card && <div className="payment-method__item">
                            <input id="payment-form-method-existing" name="method" type="radio" value="existing" checked={this.state.selectedPaymentOption == 'existing'} onChange={(e) => {
                                this.setState({ selectedPaymentOption: e.currentTarget.checked ? 'existing' : 'paypal' })
                            }} />
                            <label htmlFor="payment-form-method-existing">Existing {this.state.card.cardType || 'Credit Card'} ending in {this.state.card.cardNumber}</label>
                        </div>}
                        {!onlyCreditcard && <>
                            <input id="payment-form-method-paypal" name="method" type="radio" value="paypal" checked={this.state.selectedPaymentOption == 'paypal'} onChange={(e) => {
                                this.setState({ selectedPaymentOption: !e.currentTarget.checked ? 'card' : 'paypal' })
                            }} />
                            <label htmlFor="payment-form-method-paypal">Paypal</label>
                        </>}
                    </div>
                    {this.state.selectedPaymentOption == 'card' && this.renderCreditCard()}
                </div>
            </div>
        </div>
    }

    renderNewSubscription() {
        return <>
            {(this.state.package!.trial_amount || 0) > 0 && this.state.trial && <div className="content-row">
                <div>
                    <div className='content-head'>
                        This Month
                    </div>
                    <div className="text">
                        ${this.state.package!.trial_amount}
                    </div>
                </div>
            </div>}
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        New Plan:
                    </div>
                    <div className="text">
                        {this.state.package!.name}
                    </div>
                </div>
            </div>
            {this.state.package?.trial_amount && this.state.trialApplicable && <div className="content-row">
                <div>
                    <div className='content-head'>
                        First {this.state.package.trial_period > 1 ? `${this.state.package.trial_period} ` : ''}Month{this.state.package.trial_period > 1 ? `s` : ''} Trial:
                    </div>
                    <div className="text">
                        ${this.state.package.trial_amount}
                    </div>
                </div>
            </div>}
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Charged Monthly{this.state.package?.trial_amount && this.state.trialApplicable ? ' Thereafter' : ''}:
                    </div>
                    <div className="text">
                        ${this.state.package!.amount}
                    </div>
                </div>
            </div>
            {this.renderCoupon()}
            {this.renderPaymentMethods()}
            <div className='content-row'>
                <div>
                    <div className='content-head right'>
                        <Button loading={this.state.subscribing} textColor={'white'} buttonText={'Subscribe Now'} hoverEffect={true} onClick={() => {
                            this.subscribe()
                        }} />
                    </div>
                </div>
            </div>
        </>
    }

    renderOneTime() {
        return <>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Charged Now:
                    </div>
                    <div className="text">
                        ${this.state.package!.amount}
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Amount Of Credits Added:
                    </div>
                    <div className="text">
                        {this.state.package!.amount_of_credits}
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Credits Valid Until:
                    </div>
                    <div className="text">
                        {new Date(this.state.memberShipInfo!.next_billing!).toLocaleDateString()}
                    </div>
                </div>
            </div>
            {this.renderCoupon()}
            {this.renderPaymentMethods()}
            <div className='content-row'>
                <div>
                    <div className='content-head right'>
                        <Button loading={this.state.subscribing} textColor={'white'} buttonText={'Buy Now'} hoverEffect={true} onClick={() => {
                            this.subscribe()
                        }} />
                    </div>
                </div>
            </div>
        </>
    }

    renderDowngradeUpgrade() {
        if (!this.state.changeInfo) {
            return <Loader centered style={{ marginTop: 20 }} />
        }
        return <>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Charged {this.state.changeInfo.willBeFutureUpdate ? 'on ' + new Date(this.state.memberShipInfo!.next_billing!).toLocaleDateString() : 'now'}:
                    </div>
                    <div className="text">
                        ${this.state.changeInfo.info!.final_amount}
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        Current Plan:
                    </div>
                    <div className="text">
                        {this.state.memberShipInfo!.membership_type}
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        New Plan:
                    </div>
                    <div className="text">
                        {this.getPack()?.title}
                    </div>
                </div>
            </div>
            <div className="content-row">
                <div>
                    <div className='content-head'>
                        New Monthly Price:
                    </div>
                    <div className="text">
                        ${this.state.package!.amount} {this.renderAfterTrialText()}
                    </div>
                </div>
            </div>
            {this.state.changeInfo.new_cc_required && this.renderPaymentMethods(true)}
            <div className='content-row'>
                <div>
                    <div className='content-head right'>
                        <Button loading={this.state.subscribing} textColor={'white'} buttonText={this.state.changeInfo.mode == 'downgrade' ? 'Downgrade Now' : 'Upgrade Now'} hoverEffect={true} onClick={() => {
                            this.changeSubscription()
                        }} />
                    </div>
                </div>
            </div>
        </>
    }

    render() {
        if (this.state.loading || this.state.package == undefined) {
            return <Loader centered style={{ marginTop: 20 }} />
        }
        const pack = this.getPack()
        if (!pack) {
            return <Redirect to={'/pricing'} />
        }
        return <div className="Subscribe">
            <main>
                <div className='upgrade-paymentinfo'>
                    <div className="header-row">
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                <div className="text">
                                    You have selected: <span className="text-plan-selected">{pack.title}</span>
                                </div>
                                <div className="text">
                                    {this.renderOption(pack)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-row">
                        <div>
                            <div className="text mid">
                                {this.renderOptionDescription(pack)}
                            </div>
                        </div>
                    </div>
                    {pack.type == 'subscribe' && this.renderNewSubscription()}
                    {pack.type == 'one-time' && this.renderOneTime()}
                    {pack.type == 'downgrade' && this.renderDowngradeUpgrade()}
                    {pack.type == 'upgrade' && this.renderDowngradeUpgrade()}
                </div>
            </main>
        </div>
    }
}

export default withRouter(Subscribe)