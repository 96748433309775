import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { MoodQueryAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps, ManagedAlbumRow, PagingInfo, PagingInfoResponse, SoundPackage } from "bpm-sounds-generic";
import { EditModeContextConsumer, Divider, Genre } from 'bpm-sounds-generic';
import SyncManagedSoundlist from 'src/components/SyncManagedSoundlist';
import { AlbumAPI } from 'src/api/endpoints/album';
import { Analytics } from 'src/manager/analyticsmanager';

const GenreDetail: React.FC<RouteComponentProps<{ slug: string }> & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<MoodQueryAttributes>({ slug: props.match.params.slug })
    const [genre, setGenre] = React.useState<Genre>()

    React.useEffect(() => {
        setFilter({ genre: decodeURIComponent(props.match.params.slug) })
    }, [props.match.params.slug])


    const soundPackageTransform = (slug: string) => {
        return (paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> => {
            return AlbumAPI.getAlbumsByGenre(slug)(paging).then((data) => {
                setGenre(data.data.genre)
                Analytics.trackView('genre', genre?.name)
                console.log(data)
                return { data: data.data.packs, pagination: data.pagination }
            })
        }
    }


    return (
        <>
            <Divider small />

            <ManagedAlbumRow
                {...props}
                context={'genre_packs'}
                title={genre ? genre.name : ''}
                apiFunc={soundPackageTransform(decodeURIComponent(props.match.params.slug))} />

            <Divider />
            <SyncManagedSoundlist
                key={filter.slug} {...props}
                pagingMode='manual'
                context={'genre:' + props.match.params.slug}
                apiFunc={SoundAPI.getSounds}
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} hideFilterKeys={['collection_id']} leftHeaderActionRenderer={() => {
                    return <EditModeContextConsumer>{edit => {
                        // if (edit) {
                        //     return <EditMode driveId={filter.collection_id!} />
                        // }
                        return null
                    }}</EditModeContextConsumer>
                }} />
        </>
    )
}

export default withRouter(GenreDetail)