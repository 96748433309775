import React from 'react'
import CardGridItem, { CardGridItem as CardGridItemProps } from './CardGridItem'

interface CardGrid {
    colMaxWidth: string | number;
    cardGridItems: CardGridItemProps[]
}

const CardGrid = (props: CardGrid) => {
    return <>
        <div className="CardGrid"
            style={{
                gridTemplateColumns: `repeat(auto-fill, minmax(22rem, ${props.colMaxWidth}))`,
            }}>
            {props.cardGridItems.map((CardGridItemProps, i) => {
                return <React.Fragment key={i}> <CardGridItem {...CardGridItemProps} maxWidth={props.colMaxWidth} /> </React.Fragment>
            })}
        </div>
    </>
}

export default CardGrid