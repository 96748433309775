import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps } from "bpm-sounds-generic";
import { ManagedAlbumRow, Divider } from 'bpm-sounds-generic';
import { AlbumAPI } from '../api/endpoints/album';

const NewAlbums: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    return (
        <div className="NewAlbums">
            <Divider small />
            <ManagedAlbumRow {...props} context={'newest_packs'}
                title='New Albums' apiFunc={AlbumAPI.getNewestAlbums}
                defaultExpanded={true}
            />
            <Divider />
        </div>
    )
}

export default withRouter(NewAlbums)