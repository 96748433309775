import { EventEmitter } from "events";
import { CollectionAPI } from "../api/endpoints/collections";
import { Collection } from "../api/models/collection";
import * as toastr from 'toastr'
import { Sound } from "bpm-sounds-generic";
import { Analytics } from "./analyticsmanager";

export const COLLECTION_LOADED_EVENT = 'collections-loaded'

export class CollectionManager extends EventEmitter {
    private static _instance = new CollectionManager()
    collections: Collection[] = [];

    public static instance() {
        return CollectionManager._instance;
    }

    private constructor() {
        super();
    }

    public loadCollections() {
        return CollectionAPI.getCollections().then((collections) => {
            this.collections = collections;
            this.emit(COLLECTION_LOADED_EVENT, this.collections)
        })
    }

    public createCollection(name: string) {
        return CollectionAPI.createCollection(name).then(collection => {
            this.collections.push(collection);
            this.emit(COLLECTION_LOADED_EVENT, this.collections)
        })
    }

    public deleteCollection(id: string) {
        return CollectionAPI.deleteCollection(id).then(() => {
            this.collections = this.collections.filter(collection => collection.id !== id)
            this.emit(COLLECTION_LOADED_EVENT, this.collections)
        })
    }

    public addSoundTocollection(sound: Sound, collection: Collection) {
        Analytics.trackClick('add_to_collection_sound', sound.id.toString())
        return CollectionAPI.addToCollection(collection.id, sound.id).then(() => {
            toastr.success('Added ' + sound.name + ' to ' + collection.name)
        })
    }

    public removeFromcollection(collection_id: string, sound_id: string) {
        return CollectionAPI.removeFromCollection(collection_id, sound_id).then(() => {
            this.emit(COLLECTION_LOADED_EVENT, this.collections)
        })
    }


    public sortCollections(collections: Collection[]) {
        return collections.sort((collection1: Collection, collection2: Collection) => new Date(collection1.created_at).getTime() - new Date(collection2.created_at).getTime())
    }
}
