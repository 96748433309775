import App from './App';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './styles/main.scss';
import { BPMSoundsGeneric, setTranslations } from 'bpm-sounds-generic';
import { Session } from 'bpm-client-api';
import { SoundAPI } from './api/endpoints/sound';
import { AlbumAPI } from './api/endpoints/album';
import { Environment } from './env';
import { MetaAPI } from './api/endpoints/meta';
import { AdminAPI } from './api/admin';
import { CollectionManager } from './manager/collectionmanager';

import Bugsnag from '@bugsnag/js'
import { Client } from "@bugsnag/core";
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BrowserRouter } from 'react-router-dom';
import { translations } from './translations';
import register from './registerServiceWorker';

setTranslations(translations)
Session.setAPIURL(Environment.GENERIC_API); declare global {
    interface Window {
        bugsnagClient?: Client;
    }
}

let ErrorBoundary = class extends React.Component { public render() { return this.props.children } }
if (process.env.REACT_APP_STAGE != 'local') {
    const pack = require('../package.json');
    const bugsnagClient = Bugsnag.start({
        apiKey: '096cf48f8670ff2798f04d456a6b8e1b', plugins: [new BugsnagPluginReact()],
        releaseStage: process.env.NODE_ENV,
        appVersion: pack.version
    })
    window.bugsnagClient = bugsnagClient
    ErrorBoundary = bugsnagClient.getPlugin('react')!.createErrorBoundary(React)! as React.ComponentClass
}

BPMSoundsGeneric.initialize({
    loadGenres: MetaAPI.getGenres,
    loadKeys: AdminAPI.getKeys,
    loadSimiliarSoundPackages: AlbumAPI.getSimilarAlbums,
    updateSoundPackage: AdminAPI.updateAlbum,
    updateSound: AdminAPI.updateSound,
    driveManager: {
        loadDrives: CollectionManager.instance().loadCollections.bind(CollectionManager.instance()),
        createDrive: CollectionManager.instance().createCollection.bind(CollectionManager.instance()),
        deleteDrive: CollectionManager.instance().deleteCollection.bind(CollectionManager.instance()),
        removeFromDrive: CollectionManager.instance().removeFromcollection.bind(CollectionManager.instance()),
        addSoundToDrive: CollectionManager.instance().addSoundTocollection.bind(CollectionManager.instance()),
        sortDrives: CollectionManager.instance().sortCollections.bind(CollectionManager.instance())
    },
    getSound: SoundAPI.getSound,
    addSoundTags: AdminAPI.addSoundTags,
    deleteSoundTag: AdminAPI.deleteSoundTag,
    getSimilarSounds: SoundAPI.getSimilarSounds,
    getBanner: MetaAPI.getBanner,
    onStream: (sound) => {
        console.log(sound)
        return SoundAPI.streamSoundVersion(sound, window.location.pathname)
    },
})

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);
register()
