import { BannerRenderer, DefaultProps, Divider, ManagedAlbumRow, Sound, SoundDisplayAttributes, SoundsList, SoundsListHeader, translations } from 'bpm-sounds-generic'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { SoundAPI } from '../api/endpoints/sound'
import { AlbumQueryAttributes } from '../api/endpoints/types'
import { AlbumAPI } from '../api/endpoints/album'
import SyncManagedSoundlist from '../components/SyncManagedSoundlist'
import { Analytics } from '../manager/analyticsmanager'
import { MetaAPI } from 'src/api/endpoints/meta'
import { SyncSound } from 'src/api/models/sound'


const Browse: React.FC<RouteComponentProps<{ id: string }> & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<AlbumQueryAttributes & SoundDisplayAttributes>({})
    const [sounds, setSounds] = useState<Sound[]>()
    const history = useHistory()

    useEffect(() => {
        SoundAPI.getSounds({ search: '' }, { skip: 0, limit: 10 }).then((resp) => {
            setSounds(resp.data)
        })
    }, [])

    return (
        <div className="Browse">
            <Divider small />
            <BannerRenderer location={'browse'} onItemClick={() => Analytics.trackClick} />
            <Divider small />
            <ManagedAlbumRow {...props}
                context={'new_albums'}
                title='New Albums'
                apiFunc={AlbumAPI.getNewestAlbums}
                defaultExpanded={false}
                onViewAllClick={() => history.push('/new_albums')}
            />
            <Divider small />

            <div className="soundslist-header-wrapper">
                <SoundsListHeader
                    title={translations.Sounds}
                    sounds={sounds}
                    {...props}
                />
                <div className="view-all-songs" onClick={() => history.push('/Search')}> View all Songs</div>
            </div>


            <SoundsList
                {...props}
                sounds={sounds}
                filter={filter}
                context={'featured_songs'}
                onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }}
            />
            <Divider />

            <ManagedAlbumRow {...props}
                context={'trending_albums'}
                title='Trending Albums'
                apiFunc={AlbumAPI.getTrendingAlbums}
                defaultExpanded={false}
            />

        </div>
    )
}

export default Browse